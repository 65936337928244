import moment from 'moment';

export interface StudentJson {
  // eslint-disable-next-line camelcase
  student_first_name?: string | null,
  // eslint-disable-next-line camelcase
  student_last_name?: string | null,
  // eslint-disable-next-line camelcase
  student_birth_date?: string | null,
  // eslint-disable-next-line camelcase
  session_code?: string | null,
}

export default class Student {
  studentFirstName: string | null;

  studentLastName: string | null;

  studentBirthDate: moment.Moment | null;

  sessionCode: string | null;

  constructor(data: StudentJson = {}) {
    this.studentFirstName = undefined === data.student_first_name || null === data.student_first_name ? null : data.student_first_name;
    this.studentLastName = undefined === data.student_last_name || null === data.student_last_name ? null : data.student_last_name;
    // eslint-disable-next-line max-len
    this.studentBirthDate = undefined === data.student_birth_date || null === data.student_birth_date ? null : moment(data.student_birth_date);
    this.sessionCode = undefined === data.session_code || null === data.session_code ? null : data.session_code;
  }

  get studentFullName(): string {
    return `${this.studentFirstName} ${this.studentLastName}`;
  }

  toJSON(): StudentJson {
    return {
      student_first_name: this.studentFirstName,
      student_last_name: this.studentLastName,
      student_birth_date: null === this.studentBirthDate ? null : this.studentBirthDate.toISOString(),
      session_code: this.sessionCode,
    };
  }
}
