import { Component } from 'vue';

import HeaderKind from './HeaderKind';

export interface RouteOptionsParameters {
  component?: Component;
  displaySidebar?: boolean;
  headerKind?: HeaderKind;
}

export default class RouteOptions {
  readonly component: Component;

  readonly headerKind: HeaderKind = HeaderKind.SIMPLE;

  readonly displaySidebar: boolean = false;

  private constructor(values: RouteOptionsParameters = {}) {
    if (undefined === values.component) {
      throw new Error('Route component required');
    } else {
      this.component = values.component;
    }

    if (undefined !== values.displaySidebar) {
      this.displaySidebar = values.displaySidebar;
    }

    if (undefined !== values.headerKind) {
      this.headerKind = values.headerKind;
    }
  }

  static load(options?: RouteOptionsParameters): RouteOptions {
    return new RouteOptions(options);
  }
}
