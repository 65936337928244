import moment from 'moment';

import ExerciseMedia, { ExerciseMediaJson } from './exerciseMedia';
import Language, { LanguageJson } from './language';
import Question, { QuestionJson } from './question';
import ExerciseKind from './exerciseKind';
import MediaKind from './mediaKind';
import AnswerKind from './answerKind';

export interface ExerciseJson {
  id?: number | null,
  guid?: string | null,
  label?: string | null,
  language?: LanguageJson | null,
  // eslint-disable-next-line camelcase
  level_label?: string | null,
  kind?: number | null,
  // eslint-disable-next-line camelcase
  media_kind?: number | null,
  // eslint-disable-next-line camelcase
  answer_kind?: number | null,
  // eslint-disable-next-line camelcase
  last_updated_at?: string | null,
  // eslint-disable-next-line camelcase
  main_media?: ExerciseMediaJson | null,
  questions?: QuestionJson[] | null,
}

export default class Exercise {
  id: number | null;

  guid: string | null;

  label: string | null;

  levelLabel: string | null;

  language: Language | null;

  kind: ExerciseKind | null;

  mediaKind: MediaKind | null;

  answerKind: AnswerKind | null;

  lastUpdatedAt: moment.Moment | null;

  mainMedia: ExerciseMedia | null;

  questions: Question[] = [];

  constructor(data: ExerciseJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.guid = undefined === data.guid || null === data.guid ? null : data.guid;
    this.label = undefined === data.label || null === data.label ? null : data.label;
    this.language = undefined === data.language || null === data.language ? null : new Language(data.language);
    this.levelLabel = undefined === data.level_label || null === data.level_label ? null : data.level_label;
    this.kind = undefined === data.kind || null === data.kind ? null : data.kind;
    this.mediaKind = undefined === data.media_kind || null === data.media_kind ? null : data.media_kind;
    this.answerKind = undefined === data.answer_kind || null === data.answer_kind ? null : data.answer_kind;
    this.lastUpdatedAt = undefined === data.last_updated_at || null === data.last_updated_at ? null : moment(data.last_updated_at);
    this.mainMedia = undefined === data.main_media || null === data.main_media ? null : new ExerciseMedia(data.main_media);

    if (null === data.questions || undefined === data.questions) {
      this.questions = [];
    } else {
      this.questions = data.questions.map((question) => new Question(question));
    }
  }

  get languageName(): string | null {
    return null === this.language ? null : this.language.name;
  }

  toJSON(): ExerciseJson {
    return {
      id: this.id,
      guid: this.guid,
      label: this.label,
      language: null === this.language ? null : this.language.toJSON(),
      level_label: this.levelLabel,
      kind: this.kind,
      media_kind: this.mediaKind,
      answer_kind: this.answerKind,
      last_updated_at: null === this.lastUpdatedAt ? null : this.lastUpdatedAt.toISOString(),
      main_media: this.mainMedia,
      questions: this.questions.map((question: Question): QuestionJson => question.toJSON()),
    };
  }
}
