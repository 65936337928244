interface ExerciseLike {
  // eslint-disable-next-line camelcase
  media_kind?: number | null;
  mediaKind?: number | null;
}

const getMediaKind = (exercise: ExerciseLike): number | null => {
  if (undefined !== exercise.media_kind) {
    return exercise.media_kind;
  }

  if (undefined !== exercise.mediaKind) {
    return exercise.mediaKind;
  }

  return null;
};

enum MediaKind {
  VIDEO = 0,
  AUDIO = 1,
  IMAGE = 2,
  TEXT = 3,
  PDF = 4,
}

const isAudio = (exercise: ExerciseLike): boolean => MediaKind.AUDIO === getMediaKind(exercise);

const isImage = (exercise: ExerciseLike): boolean => MediaKind.IMAGE === getMediaKind(exercise);

const isText = (exercise: ExerciseLike): boolean => MediaKind.TEXT === getMediaKind(exercise);

const isPdf = (exercise: ExerciseLike): boolean => MediaKind.PDF === getMediaKind(exercise);

const isVideo = (exercise: ExerciseLike): boolean => MediaKind.VIDEO === getMediaKind(exercise);

export default MediaKind;

export {
  isAudio,
  isImage,
  isText,
  isPdf,
  isVideo,
};
