
import { Component, Vue } from 'vue-property-decorator';

import chromeLogo from '../assets/images/logo-chrome.jpg';
import firefoxLogo from '../assets/images/logo-firefox.jpg';

@Component
export default class NotSupported extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get chromeLogo(): string {
    return chromeLogo;
  }

  // eslint-disable-next-line class-methods-use-this
  get firefoxLogo(): string {
    return firefoxLogo;
  }
}
