export interface AlternativeSource {
  url: string,
  type: string,
}

export interface ExerciseMediaJson {
  id?: number | null,
  url?: string | null,
  // eslint-disable-next-line camelcase
  mime_type?: string | null,
  // eslint-disable-next-line camelcase
  alternative_sources?: AlternativeSource[],
}

export default class ExerciseMedia {
  id: number | null;

  url: string | null;

  mimeType: string | null;

  alternativeSources: AlternativeSource[];

  constructor(data: ExerciseMediaJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.url = undefined === data.url || null === data.url ? null : data.url;
    this.mimeType = undefined === data.mime_type || null === data.mime_type ? null : data.mime_type;
    this.alternativeSources = undefined === data.alternative_sources || null === data.alternative_sources ? [] : data.alternative_sources;
  }

  toJSON(): ExerciseMediaJson {
    return {
      id: this.id,
      url: this.url,
      mime_type: this.mimeType,
      alternative_sources: this.alternativeSources,
    };
  }
}
