import moment from 'moment';

export default class Countdown {
  private duration?: moment.Duration;

  private endCallback?: () => void;

  private endedAt?: moment.Moment;

  private interval?: number;

  private remaining = 0;

  private startedAt?: moment.Moment;

  private started = false;

  start(startedAt: moment.Moment, duration: moment.Duration, endCallback?: () => void): void {
    this.startedAt = moment(startedAt);
    this.duration = duration;
    this.endedAt = moment(startedAt).add(duration);
    this.endCallback = endCallback;

    this.tick();

    if (this.isEnded) {
      this.markEnded();
    } else {
      this.startCountdown();
    }
  }

  stop(): void {
    this.stopCountdown();
  }

  get formattedRemainingTime(): string {
    if (this.isEnded) {
      return '00:00:00';
    }

    const duration = moment.duration(this.remainingSeconds, 'seconds');

    return [
      `0${duration.hours()}`.substr(-2),
      `0${duration.minutes()}`.substr(-2),
      `0${duration.seconds()}`.substr(-2),
    ].join(':');
  }

  get isEnded(): boolean {
    return 0 >= this.remaining;
  }

  get isRunning(): boolean {
    return !!this.interval;
  }

  get isStarted(): boolean {
    return this.started;
  }

  get remainingSeconds(): number {
    return this.remaining || 0;
  }

  private tick(): void {
    this.remaining = this.endedAt?.diff(moment(), 'seconds') || 0;

    if (this.isEnded) {
      this.stopCountdown();

      if (this.endCallback) {
        this.endCallback();
        this.endCallback = undefined;
      }
    }
  }

  private markEnded(): void {
    this.remaining = 0;
    this.duration = undefined;
    this.endedAt = undefined;
    this.interval = undefined;
    this.startedAt = undefined;
  }

  private startCountdown(): void {
    this.started = true;
    this.interval = setInterval(() => {
      this.tick();
    }, 1000);
  }

  private stopCountdown(): void {
    this.started = false;
    if (this.interval) {
      clearInterval(this.interval);

      this.interval = undefined;

      this.markEnded();
    }
  }
}
