import moment from 'moment';

export interface StudentSessionJson {
  id?: number | null,
  // eslint-disable-next-line camelcase
  session_id?: number | null,
  guid?: string | null,
  // eslint-disable-next-line camelcase
  student_first_name?: string | null,
  // eslint-disable-next-line camelcase
  student_last_name?: string | null,
  // eslint-disable-next-line camelcase
  student_birth_date?: string | null,
  // eslint-disable-next-line camelcase
  started_at?: string | null,
  // eslint-disable-next-line camelcase
  ended_at?: string | null,
  duration?: number | null,
}

export default class StudentSession {
  id: number | null;

  sessionId: number | null;

  guid: string | null;

  studentFirstName: string | null;

  studentLastName: string | null;

  studentBirthDate: moment.Moment | null;

  startedAt: moment.Moment | null;

  endedAt: moment.Moment | null;

  duration: number | null;

  constructor(data: StudentSessionJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.sessionId = undefined === data.session_id || null === data.session_id ? null : data.session_id;
    this.guid = undefined === data.guid || null === data.guid ? null : data.guid;
    this.studentFirstName = undefined === data.student_first_name || null === data.student_first_name ? null : data.student_first_name;
    this.studentLastName = undefined === data.student_last_name || null === data.student_last_name ? null : data.student_last_name;
    // eslint-disable-next-line max-len
    this.studentBirthDate = undefined === data.student_birth_date || null === data.student_birth_date ? null : moment(data.student_birth_date);
    this.startedAt = undefined === data.started_at || null === data.started_at ? null : moment(data.started_at);
    this.endedAt = undefined === data.ended_at || null === data.ended_at ? null : moment(data.ended_at);
    this.duration = undefined === data.duration || null === data.duration ? null : data.duration;
  }

  get studentFullName(): string {
    return `${this.studentFirstName} ${this.studentLastName}`;
  }

  toJSON(): StudentSessionJson {
    return {
      id: this.id,
      session_id: this.sessionId,
      guid: this.guid,
      student_first_name: this.studentFirstName,
      student_last_name: this.studentLastName,
      student_birth_date: null === this.studentBirthDate ? null : this.studentBirthDate.toISOString(),
      started_at: null === this.startedAt ? null : this.startedAt.toISOString(),
      ended_at: null === this.endedAt ? null : this.endedAt.toISOString(),
      duration: this.duration,
    };
  }
}
