
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Route from './plugins/navigation/model/Route';

@Component
export default class Navigation extends Vue {
  get route(): Route {
    return this.$navigation.currentRoute;
  }
}
