interface ExerciseLike {
  // eslint-disable-next-line camelcase
  exercise_kind?: number | null;
  exerciseKind?: number | null;
  kind?: number | null;
}

const getExerciseKind = (exercise: ExerciseLike): number | null => {
  if (undefined !== exercise.kind) {
    return exercise.kind;
  }

  if (undefined !== exercise.exercise_kind) {
    return exercise.exercise_kind;
  }

  if (undefined !== exercise.exerciseKind) {
    return exercise.exerciseKind;
  }

  return null;
};

enum ExerciseKind {
  AUDIO_REPEAT = 1,
  QUIZ = 2,
  MULTIPLE_CHOICE = 3,
}

const isAudioRepeat = (exercise: ExerciseLike): boolean => ExerciseKind.AUDIO_REPEAT === getExerciseKind(exercise);
const isQuiz = (exercise: ExerciseLike): boolean => ExerciseKind.QUIZ === getExerciseKind(exercise);
const isMultipleChoice = (exercise: ExerciseLike): boolean => ExerciseKind.MULTIPLE_CHOICE === getExerciseKind(exercise);

export default ExerciseKind;
export {
  isAudioRepeat,
  isQuiz,
  isMultipleChoice,
};
