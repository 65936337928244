
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import SubmitSession from '../exercise/SubmitSession.vue';
import ToastMixin from '../../mixins/toast';
import ZeroPad from '../../mixins/zeropad';
import Exercise from '../../model/exercise';
import ExerciseArchive from '../../model/exerciseArchive';
import * as MediaKind from '../../model/mediaKind';
import Session from '../../model/session';
import SessionExercise from '../../model/sessionExercise';
import StudentSession from '../../model/studentSession';
import { SESSION_EXERCISE_QUESTION, SESSION_LOGIN } from '../../plugins/navigation/routes';

@Component({
  components: {
    SubmitSession,
  },
})
export default class SessionSidebar extends mixins(ToastMixin, ZeroPad) {
  @Getter('session/currentExercise')
    currentExercise!: Exercise | null;

  @Getter('session/session')
    session!: Session | null;

  @Getter('session/sessionExercises')
    sessionExercises!: SessionExercise[];

  @Getter('session/studentSession')
    studentSession!: StudentSession | null;

  @Getter('session/answers')
    answers!: {
    [key: string]: Blob,
  };

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Action('session/loadExercise')
    loadExercise;

  @Action('applicationState/toggleNavigationDisabled')
    toggleNavigationDisabled;

  @Action('applicationState/toggleSidebar')
    toggleSidebar;

  @Action('session/uploadCurrentAnswer')
    uploadCurrentAnswer;

  mounted(): void {
    this.redirect();
  }

  static isAudio(exercise: ExerciseArchive): boolean {
    return MediaKind.isAudio(exercise);
  }

  static isImage(exercise: ExerciseArchive): boolean {
    return MediaKind.isImage(exercise);
  }

  static isVideo(exercise: ExerciseArchive): boolean {
    return MediaKind.isVideo(exercise);
  }

  // eslint-disable-next-line class-methods-use-this
  getExerciseIcon(exercise: ExerciseArchive): string {
    if (SessionSidebar.isVideo(exercise)) {
      return 'film';
    }

    if (SessionSidebar.isAudio(exercise)) {
      return 'headphones';
    }

    if (SessionSidebar.isImage(exercise)) {
      return 'image';
    }

    return 'book';
  }

  // eslint-disable-next-line class-methods-use-this
  getExerciseClass(exercise: ExerciseArchive): string {
    if (SessionSidebar.isVideo(exercise)) {
      return 'text-media-video';
    }

    if (SessionSidebar.isAudio(exercise)) {
      return 'text-media-audio';
    }

    if (SessionSidebar.isImage(exercise)) {
      return 'text-media-image';
    }

    return 'text-media-text';
  }

  get formattedSessionMaximalDuration(): string {
    const maximalDuration = this.session?.maximalDuration;

    if (null === maximalDuration || undefined === maximalDuration) {
      return '';
    }

    return [
      'hours',
      'minutes',
      'seconds',
    ].map((unit: string): string => {
      const length = maximalDuration[unit]();

      return this.zeroPad(length, 2);
    }).join(':');
  }

  redirect(): void {
    if (null === this.session || null === this.studentSession) {
      this.$navigation.navigate(SESSION_LOGIN);
    }
  }

  numberOfAnswer(exercise: SessionExercise): number {
    const exerciseGuid = exercise.exerciseArchive?.guid;

    if (!exerciseGuid) {
      return 0;
    }

    const regex = new RegExp(`^${exerciseGuid}/`);

    return Object.keys(this.answers).filter((key) => regex.test(key) && this.answers[key]).length;
  }

  // eslint-disable-next-line class-methods-use-this
  truncateExerciseLabel(exercise: ExerciseArchive): string {
    const label = exercise.label || '';

    if (20 >= label.length) {
      return label;
    }

    return `${label.substring(0, 20)} …`;
  }

  navigateToExercise(exercise: string): void {
    if (this.isNavigationDisabled) {
      return;
    }

    this.toggleNavigationDisabled(true);

    this.uploadCurrentAnswer()
      .then(() => {
        this.loadExercise(exercise);

        if (SESSION_EXERCISE_QUESTION !== this.$navigation.currentRoute.name) {
          this.$navigation.navigate(SESSION_EXERCISE_QUESTION);
        }

        this.toggleSidebar(false);
      })
      .catch((error: Error) => {
        this.displayError('generic', error.message);
      }).finally((): void => {
        this.toggleNavigationDisabled(false);
      });
  }
}
