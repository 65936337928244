import Vue from 'vue';

import HeaderKind from './model/HeaderKind';
import Route from './model/Route';
import * as routeNames from './routes';
import Home from '../../views/Home.vue';
import NotFound from '../../views/NotFound.vue';
import SessionExerciseQuestion from '../../views/SessionExerciseQuestion.vue';
import SessionInfo from '../../views/SessionInfo.vue';
import SessionLogin from '../../views/SessionLogin.vue';
import SessionSummary from '../../views/SessionSummary.vue';
import ResumeSession from '../../views/ResumeSession.vue';
import SessionStudentInfo from '../../views/SessionStudentInfo.vue';
import SessionExerciseSummary from '../../views/SessionExerciseSummary.vue';
import SessionTimerExpired from '../../views/SessionTimerExpired.vue';

const routes = [
  Route.create(routeNames.HOME, {
    component: Home,
  }),
  Route.create(routeNames.NOT_FOUND, {
    component: NotFound,
  }),
  Route.create(routeNames.SESSION_LOGIN, {
    component: SessionLogin,
  }),
  Route.create(routeNames.RESUME_SESSION, {
    component: ResumeSession,
  }),
  Route.create(routeNames.SESSION_EXERCISE_SUMMARY, {
    component: SessionExerciseSummary,
    displaySidebar: true,
    headerKind: HeaderKind.SESSION,
  }),
  Route.create(routeNames.SESSION_EXERCISE_QUESTION, {
    component: SessionExerciseQuestion,
    displaySidebar: true,
    headerKind: HeaderKind.SESSION,
  }),
  Route.create(routeNames.SESSION_INFO, {
    component: SessionInfo,
  }),
  Route.create(routeNames.SESSION_STUDENT_INFO, {
    component: SessionStudentInfo,
  }),
  Route.create(routeNames.SESSION_SUMMARY, {
    component: SessionSummary,
    headerKind: HeaderKind.SESSION,
  }),
  Route.create(routeNames.SESSION_TIMER_EXPIRED, {
    component: SessionTimerExpired,
    headerKind: HeaderKind.SESSION,
  }),
];

type RouteList = {
  [key: string]: Route
};

export class Navigation {
  private currentRouteName: string = routeNames.HOME;

  readonly routes: RouteList;

  constructor() {
    this.routes = routes.reduce((routeList: RouteList, route: Route): RouteList => {
      Object.assign(routeList, {
        [route.name]: route,
      });

      return routeList;
    }, {});
  }

  get currentRoute(): Route {
    return this.routes[this.currentRouteName];
  }

  navigate(routeName: string): void {
    if (routeName in this.routes) {
      this.currentRouteName = routeName;

      if ('gtm' in Vue) {
        Vue.gtm.trackView(this.currentRoute.name, this.currentRoute.name);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(`Route "${routeName}" not found`);
      this.currentRouteName = routeNames.NOT_FOUND;
    }
  }
}

export default Vue.observable(new Navigation());
