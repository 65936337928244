
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
  navigateHome(): void {
    this.$router.push({
      name: 'app',
    });
  }
}
