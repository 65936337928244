import ExerciseMedia, { ExerciseMediaJson } from './exerciseMedia';

export interface QuestionChoiceJson {
  id?: number | null,
  guid?: string | null,
  position?: number | null,
  media?: ExerciseMediaJson | null,
  // eslint-disable-next-line camelcase
  choice_content?: string | null,
}

export default class QuestionChoice {
  id: number | null;

  guid: string | null;

  position: number | null;

  media: ExerciseMedia | null;

  choiceContent: string | null;

  constructor(data: QuestionChoiceJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.guid = undefined === data.guid || null === data.guid ? null : data.guid;
    this.position = undefined === data.position || null === data.position ? null : data.position;
    this.media = undefined === data.media || null === data.media ? null : new ExerciseMedia(data.media);
    this.choiceContent = undefined === data.choice_content || null === data.choice_content ? null : data.choice_content;
  }

  get isAudio(): boolean {
    return null !== this.media;
  }

  get isText(): boolean {
    return null !== this.choiceContent;
  }

  toJSON(): QuestionChoiceJson {
    return {
      id: this.id,
      guid: this.guid,
      position: this.position,
      media: null === this.media ? null : this.media.toJSON(),
      choice_content: this.choiceContent,
    };
  }
}
