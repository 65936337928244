import ExerciseKind from './exerciseKind';
import MediaKind from './mediaKind';
import AnswerKind from './answerKind';

export interface ExerciseArchiveJson {
  id?: number | null,
  guid?: string | null,
  label?: string | null,
  kind?: number | null,
  // eslint-disable-next-line camelcase
  media_kind?: number | null,
  // eslint-disable-next-line camelcase
  answer_kind?: number | null,
  // eslint-disable-next-line camelcase
  questions_count?: number | null,
}

export default class ExerciseArchive {
  id: number | null;

  guid: string | null;

  label: string | null;

  kind: ExerciseKind | null;

  mediaKind: MediaKind | null;

  answerKind: AnswerKind | null;

  questionsCount: number | null;

  constructor(data: ExerciseArchiveJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.guid = undefined === data.guid || null === data.guid ? null : data.guid;
    this.label = undefined === data.label || null === data.label ? null : data.label;
    this.kind = undefined === data.kind || null === data.kind ? null : data.kind;
    this.mediaKind = undefined === data.media_kind || null === data.media_kind ? null : data.media_kind;
    this.answerKind = undefined === data.answer_kind || null === data.answer_kind ? null : data.answer_kind;
    this.questionsCount = undefined === data.questions_count || null === data.questions_count ? null : data.questions_count;
  }

  toJSON(): ExerciseArchiveJson {
    return {
      id: this.id,
      guid: this.guid,
      label: this.label,
      kind: this.kind,
      media_kind: this.mediaKind,
      answer_kind: this.answerKind,
      questions_count: this.questionsCount,
    };
  }
}
