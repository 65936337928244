export interface LanguageJson {
  id?: number | null,
  code?: string | null,
  name?: string | null,
}

export default class Language {
  id: number | null;

  code: string | null;

  name: string | null;

  constructor(data: LanguageJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.code = undefined === data.code || null === data.code ? null : data.code;
    this.name = undefined === data.name || null === data.name ? null : data.name;
  }

  toJSON(): LanguageJson {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
    };
  }
}
