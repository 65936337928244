interface ExerciseLike {
  // eslint-disable-next-line camelcase
  answer_kind?: number | null;
  answerKind?: number | null;
}

enum AnswerKind {
  AUDIO = 0,
  TEXT = 1,
  MULTIPLE_CHOICE = 2,
}

const getAnswerKind = (exercise: ExerciseLike): number | null => {
  if (undefined !== exercise.answer_kind) {
    return exercise.answer_kind;
  }

  if (undefined !== exercise.answerKind) {
    return exercise.answerKind;
  }

  return null;
};

const isAudio = (exercise: ExerciseLike): boolean => AnswerKind.AUDIO === getAnswerKind(exercise);

const isText = (exercise: ExerciseLike): boolean => AnswerKind.TEXT === getAnswerKind(exercise);

const isMultipleChoice = (exercise: ExerciseLike): boolean => AnswerKind.MULTIPLE_CHOICE === getAnswerKind(exercise);

export default AnswerKind;
export {
  isAudio,
  isText,
  isMultipleChoice,
};
