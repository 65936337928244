
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import LocaleSwitcher from '../LocaleSwitcher.vue';
import Session from '../../model/session';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultLogo = require('../../assets/images/logo.png');

@Component({
  components: { LocaleSwitcher },
})
export default class SimpleHeader extends Vue {
  @Getter('session/session')
    session!: Session | null;

  // eslint-disable-next-line class-methods-use-this
  get logo(): string {
    return defaultLogo;
  }
}
