
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
  Getter,
} from 'vuex-class';
import moment from 'moment/moment';

import Route from './plugins/navigation/model/Route';
import Recorder from './plugins/recorder';
import Header from './components/Header.vue';

@Component({
  components: {
    Header,
  },
})
export default class App extends Vue {
  @Getter('language/language')
    currentLanguage;

  @Getter('session/studentSession')
    studentSession;

  get route(): Route {
    return this.$navigation.currentRoute;
  }

  created(): void {
    if (null !== this.currentLanguage) {
      moment.locale(this.currentLanguage);
    }

    if (!Recorder.isSupported) {
      this.$router.push('not-supported');
    }

    window.addEventListener('beforeunload', this.beforeUnload);
  }

  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  // eslint-disable-next-line class-methods-use-this
  beforeUnload(event: Event): boolean {
    if (null === this.studentSession) {
      return false;
    }

    event.preventDefault();

    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;

    return true;
  }

  @Watch('currentLanguage')
  currentLanguageChanged(language: string): void {
    moment.locale(language);
    this.$i18n.locale = language;
  }
}
