
import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import AudioQuestion from '../question/AudioQuestion.vue';
import TextQuestion from '../question/TextQuestion.vue';
import Question from '../../../model/question';
import ClassicEditor from '../../../plugins/ckeditor';

interface WordCountUpdateInterface {
  characters: number;

  words: number;
}

const counts = Vue.observable({
  characters: 0,
  words: 0,
});

@Component({
  components: {
    AudioQuestion,
    TextQuestion,
  },
})
export default class TextAnswer extends Vue {
  @Prop()
    question!: Question;

  @Getter('session/currentAnswer')
    currentAnswer!: string | null;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Action('session/storeAnswer')
    storeAnswer;

  @Ref()
  readonly audioQuestion!: AudioQuestion;

  readonly editor = ClassicEditor;

  readonly editorConfig = {
    language: this.$i18n.locale,
    wordCount: {
      onUpdate: (updatedCounts: WordCountUpdateInterface): void => {
        counts.characters = updatedCounts.characters;
        counts.words = updatedCounts.words;
      },
    },
  };

  get answer(): string {
    return null === this.currentAnswer ? '' : this.currentAnswer;
  }

  set answer(answer: string) {
    this.storeAnswer('' === answer ? null : answer);
  }

  // eslint-disable-next-line class-methods-use-this
  get characterCount(): number {
    return counts.characters;
  }

  // eslint-disable-next-line class-methods-use-this
  get wordCount(): number {
    return counts.words;
  }

  finishInteraction(): void {
    if (this.audioQuestion) {
      this.audioQuestion.finishInteraction();
    }
  }
}
