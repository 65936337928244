
import { Component, Vue } from 'vue-property-decorator';
import {
  Action,
  Getter,
} from 'vuex-class';

import { SessionJson } from '../model/session';
import { RESUME_SESSION, SESSION_INFO } from '../plugins/navigation/routes';

@Component
export default class SessionLogin extends Vue {
  codeSession = '';

  invalidSessionCode = false;

  @Getter('session/studentSession')
    studentSession;

  @Action('session/setSessionInfo')
    setSessionInfo;

  mounted(): void {
    if (null !== this.studentSession) {
      this.$navigation.navigate(RESUME_SESSION);
    }
  }

  async compareSessionCode(event: Event): Promise<boolean> {
    event.preventDefault();

    this.$api
      .get(`session-archive/code/${this.codeSession.toUpperCase()}`)
      .then((result: SessionJson) => {
        if (result.id) {
          if (!result.cloud) {
            throw new Error('Not a cloud session');
          }

          this.setSessionInfo(result).then(() => {
            this.$navigation.navigate(SESSION_INFO);
          });
        }
      })
      .catch(() => {
        this.invalidSessionCode = true;
        this.codeSession = '';
      });

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  forceUppercase(event: InputEvent, property: string): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;
    const start: number = eventTarget.selectionStart || 0;

    eventTarget.value = eventTarget.value.toUpperCase();
    this.$set(this, property, eventTarget.value);

    eventTarget.setSelectionRange(start, start);
  }
}
