
import { Component, Vue } from 'vue-property-decorator';

import { SESSION_LOGIN } from '../plugins/navigation/routes';

@Component
export default class Home extends Vue {
  mounted(): void {
    this.redirect();
  }

  redirect(): void {
    this.$navigation.navigate(SESSION_LOGIN);
  }
}
