
import moment from 'moment';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  Getter,
  Action,
} from 'vuex-class';

import ToastMixin from '../mixins/toast';
import ZeroPad from '../mixins/zeropad';
import Session from '../model/session';
import StudentSession from '../model/studentSession';
import { SESSION_EXERCISE_SUMMARY, SESSION_LOGIN } from '../plugins/navigation/routes';
import Student, { StudentJson } from '../model/student';

const FIRST_YEAR = 1900;

@Component
export default class SessionStudentInfo extends mixins(ToastMixin, ZeroPad) {
  readonly days: number[] = [...Array(31).keys()].map((day: number): number => day + 1);

  readonly months: string[] = moment.months().map((month: string): string => `${month[0].toUpperCase()}${month.substr(1)}`);

  readonly years: number[] = [...Array(this.currentYear - FIRST_YEAR + 1).keys()].map((year: number): number => FIRST_YEAR + year);

  dateDay = 1;

  dateMonth = 0;

  dateYear = this.currentYear;

  modalVisible = false;

  student: Student = new Student();

  @Getter('student/student')
    previousStudent!: Student | null;

  @Getter('session/session')
    session!: Session | null;

  @Action('student/setInfoStudent')
    setInfoStudent;

  @Action('session/setStudentSession')
    setStudentSession;

  @Action('session/finishSession')
    finishSession;

  hideModal(): void {
    this.modalVisible = false;
  }

  showModal(): void {
    if ((this.$refs.storeStudentForm as HTMLFormElement).reportValidity()) {
      this.modalVisible = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get currentYear(): number {
    return (new Date()).getFullYear();
  }

  get formattedSessionMaximalDuration(): string {
    const maximalDuration = this.session?.maximalDuration;

    if (null === maximalDuration || undefined === maximalDuration) {
      return '';
    }

    return [
      'hours',
      'minutes',
      'seconds',
    ].map((unit: string): string => {
      const length = maximalDuration[unit]();

      return this.zeroPad(length, 2);
    }).join(':');
  }

  mounted(): void {
    if (null === this.session) {
      this.finishSession();

      return;
    }

    const studentData: StudentJson = {
      session_code: this.session?.code,
      student_birth_date: moment.utc({
        year: this.dateYear,
        month: this.dateMonth,
        day: this.dateDay,
      }).toISOString(true),
    };

    if (this.previousStudent) {
      studentData.student_first_name = this.previousStudent.studentFirstName;
      studentData.student_last_name = this.previousStudent.studentLastName;

      if (null !== this.previousStudent.studentBirthDate) {
        this.dateYear = this.previousStudent.studentBirthDate.year();
        this.dateMonth = this.previousStudent.studentBirthDate.month();
        this.dateDay = this.previousStudent.studentBirthDate.date();
        studentData.student_birth_date = this.previousStudent.studentBirthDate.toISOString(true);
      }
    }

    this.student = new Student(studentData);
  }

  setStudentBirthDate(): void {
    this.student.studentBirthDate = moment.utc({
      year: this.dateYear,
      month: this.dateMonth,
      day: this.dateDay,
    });
  }

  backToSessionLoginPage(): void {
    this.finishSession();
    this.$navigation.navigate(SESSION_LOGIN);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async storeStudent(event: Event): Promise<any> {
    event.preventDefault();

    return this.$api
      .post('session/', this.student.toJSON())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((studentSession: any) => {
        if (!('guid' in studentSession)) {
          throw new Error('Missing GUID');
        }

        return this.setInfoStudent(this.student)
          .then(() => this.setStudentSession(new StudentSession({
            guid: studentSession.guid,
            student_first_name: this.student.studentFirstName,
            student_last_name: this.student.studentLastName,
            student_birth_date: this.student.studentBirthDate?.toISOString(),
            started_at: moment().toISOString(),
          })))
          .then(() => {
            this.displaySuccess('generic', 'toast.app.success.session_started');
            this.$navigation.navigate(SESSION_EXERCISE_SUMMARY);
          });
      })
      .catch((error: Error) => {
        this.displayError('server_error', error.message);
      });
  }
}
