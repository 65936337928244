import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import FontBackgroundColorPlugin from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import FontColorPlugin from '@ckeditor/ckeditor5-font/src/fontcolor';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount';

export default class ClassicEditor extends ClassicEditorBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static builtinPlugins: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static defaultConfig: any;
}

ClassicEditor.builtinPlugins = [
  EssentialsPlugin,
  AutoformatPlugin,
  BoldPlugin,
  FontBackgroundColorPlugin,
  FontColorPlugin,
  ItalicPlugin,
  ListPlugin,
  ParagraphPlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  UnderlinePlugin,
  WordCountPlugin,
];

ClassicEditor.defaultConfig = {
  toolbar: [
    'undo', 'redo', '|',
    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
    'numberedList', 'bulletedList', '|',
    'fontColor', 'fontBackgroundColor',
  ],
};
