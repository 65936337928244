import { ActionContext, GetterTree, MutationTree } from 'vuex';

import RootState from '../rootState';

class ApplicationState {
  currentRoute: string | null = null;

  isNavigationDisabled = false;

  isSidebarVisible = false;
}

type ApplicationContext = ActionContext<ApplicationState, RootState>;

const getters: GetterTree<ApplicationState, RootState> = {
  currentRoute: (state: ApplicationState): string | null => state.currentRoute,
  isNavigationDisabled: (state: ApplicationState): boolean => state.isNavigationDisabled,
  isSidebarVisible: (state: ApplicationState): boolean => state.isSidebarVisible,
};

const mutations: MutationTree<ApplicationState> = {
  toggleNavigationDisabled(state: ApplicationState, disabled: boolean): void {
    state.isNavigationDisabled = disabled;
  },

  setSidebarVisible(state: ApplicationState, show: boolean): void {
    state.isSidebarVisible = show;
  },
};

const actions = {
  toggleNavigationDisabled({ commit, getters: { isNavigationDisabled } }: ApplicationContext, disabled: boolean | null = null): void {
    if (null === disabled) {
      commit('toggleNavigationDisabled', !isNavigationDisabled);
    } else {
      commit('toggleNavigationDisabled', disabled);
    }
  },

  toggleSidebar({ commit, getters: { isSidebarVisible } }: ApplicationContext, show: boolean | null = null): void {
    if (null === show) {
      commit('setSidebarVisible', !isSidebarVisible);
    } else {
      commit('setSidebarVisible', show);
    }
  },
};

export default {
  namespaced: true,
  state: new ApplicationState(),
  getters,
  mutations,
  actions,
};
