
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ExerciseSubjectPdf from './subject/ExerciseSubjectPdf.vue';
import Exercise from '../../model/exercise';
import {
  isAudio,
  isImage,
  isPdf,
  isText,
  isVideo,
} from '../../model/mediaKind';

@Component({
  components: {
    ExerciseSubjectPdf,
  },
})
export default class ExerciseSubject extends Vue {
  @Prop()
    exercise!: Exercise;

  @Getter('oauth/accessToken')
    accessToken!: string | null;

  get isAudio(): boolean {
    return isAudio(this.exercise);
  }

  get isImage(): boolean {
    return isImage(this.exercise);
  }

  get isPdf(): boolean {
    return isPdf(this.exercise);
  }

  get isText(): boolean {
    return isText(this.exercise);
  }

  get isVideo(): boolean {
    return isVideo(this.exercise);
  }

  get mediaUrl(): string | null {
    if (null === this.exercise.mainMedia) {
      return null;
    }

    if (null === this.accessToken) {
      this.$api.getAccessToken();
    }

    return this.$api.buildMediaUrl(this.exercise.mainMedia, this.accessToken);
  }
}
