var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"session-sidebar"},[_c('div',{staticClass:"d-lg-none"},[_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t("messages.app.ui.establishment")))]),_c('p',{staticClass:"font-weight-bold"},[(null !== _vm.session.establishmentLogoUrl)?[_c('img',{staticClass:"img-fluid establishment-logo",attrs:{"src":_vm.session.establishmentLogoUrl,"alt":_vm.session.establishmentName}}),_c('br')]:_vm._e(),_vm._v(" "+_vm._s(_vm.session.establishmentName)+" ")],2)]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t("messages.app.ui.teacher")))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.session.teacherFullName))]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t("messages.app.ui.class")))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.session.className))]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t("messages.app.ui.session_code")))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.session.code))]),(null !== _vm.session.expiresAt)?[_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t('messages.app.ui.session_expires_at')))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.session.expiresAt.format('L LT')))])]:_vm._e(),(null !== _vm.session.maximalDuration)?[_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t('messages.app.ui.session_maximal_duration')))]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formattedSessionMaximalDuration))])]:_vm._e(),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(_vm.$t("messages.app.ui.exercise_list")))]),_vm._l((_vm.sessionExercises),function(exercise){return _c('div',{key:exercise.exerciseArchive.guid,class:[
      'exercise-link', {
        active: _vm.currentExercise.guid === exercise.exerciseArchive.guid,
      }]},[_c('button',{staticClass:"btn btn-link btn-block p-0 d-flex align-items-center justify-content-between stretched-link text-decoration-none",attrs:{"type":"button","disabled":_vm.isNavigationDisabled},on:{"click":function($event){return _vm.navigateToExercise(exercise.exerciseArchive.guid)}}},[_c('span',[_c('fa-icon',{class:[
            _vm.getExerciseClass(exercise.exerciseArchive),
            'mr-2',
          ],attrs:{"size":"lg","icon":_vm.getExerciseIcon(exercise.exerciseArchive)}}),_c('span',{staticClass:"text-dark font-weight-bold"},[_vm._v(" "+_vm._s(_vm.truncateExerciseLabel(exercise.exerciseArchive))+" ")])],1),_c('fa-icon',{staticClass:"text-dark",attrs:{"icon":"chevron-right"}})],1),_c('b-progress',{attrs:{"height":".5rem","variant":"primary","max":exercise.exerciseArchive.questionsCount,"value":_vm.numberOfAnswer(exercise)}})],1)}),_c('div',{staticClass:"mt-auto"},[_c('SubmitSession',{attrs:{"disabled":_vm.isNavigationDisabled}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }