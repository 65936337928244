
import moment from 'moment';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import ToastMixin from '../../mixins/toast';
import { SESSION_LOGIN } from '../../plugins/navigation/routes';

@Component
export default class SubmitSession extends mixins(ToastMixin) {
  modalVisible = false;

  submitStudentSessionPending = false;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Getter('session/studentSession')
    studentSession;

  @Action('session/finishSession')
    finishSession;

  @Action('session/uploadCurrentAnswer')
    uploadCurrentAnswer;

  hideModal(): void {
    this.modalVisible = false;
  }

  showModal(): void {
    if (this.disabled) {
      return;
    }

    this.modalVisible = true;
  }

  submitStudentSession(event: Event): void {
    event.preventDefault();

    this.submitStudentSessionPending = true;

    const startedAt = this.studentSession?.startedAt;
    const sessionData: {
      duration: number | null,
    } = {
      duration: null,
    };

    if (null !== startedAt && undefined !== startedAt) {
      sessionData.duration = Math.round(
        moment.duration(moment().diff(moment(startedAt))).asMilliseconds(),
      );
    }

    this
      .uploadCurrentAnswer()
      .then(() => this.$api.put(`session/${this.studentSession?.guid}`, sessionData))
      .then(() => {
        this.finishSession();
        this.displaySuccess('session_finished.title', 'toast.app.success.session_finished.message');
        this.$navigation.navigate(SESSION_LOGIN);
      })
      .catch((error) => {
        this.submitStudentSessionPending = false;

        this.displayError('generic', error.message);
      });
  }
}
