import ExerciseArchive, { ExerciseArchiveJson } from './exerciseArchive';

export interface SessionExerciseJson {
  id?: number | null,
  position?: number | null,
  // eslint-disable-next-line camelcase
  exercise_archive?: ExerciseArchiveJson | null,
}

export default class SessionExercise {
  id: number | null;

  position: number | null;

  exerciseArchive: ExerciseArchive | null;

  constructor(data: SessionExerciseJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.position = undefined === data.position || null === data.position ? null : data.position;
    // eslint-disable-next-line max-len
    this.exerciseArchive = undefined === data.exercise_archive || null === data.exercise_archive ? null : new ExerciseArchive(data.exercise_archive);
  }

  toJSON(): SessionExerciseJson {
    return {
      id: this.id,
      position: this.position,
      exercise_archive: null === this.exerciseArchive ? null : this.exerciseArchive.toJSON(),
    };
  }
}
