import moment from 'moment';

import SessionExercise, { SessionExerciseJson } from './sessionExercise';

export interface SessionJson {
  id?: number | null,
  guid?: string | null,
  open?: boolean | null,
  closed?: boolean | null,
  // eslint-disable-next-line camelcase
  last_updated_at?: string | null,
  code?: string | null,
  // eslint-disable-next-line camelcase
  establishment_name?: string | null,
  // eslint-disable-next-line camelcase
  establishment_logo_url?: string | null,
  // eslint-disable-next-line camelcase
  class_name?: string | null,
  // eslint-disable-next-line camelcase
  room_name?: string | null,
  cloud?: boolean | null,
  // eslint-disable-next-line camelcase
  teacher_first_name?: string | null,
  // eslint-disable-next-line camelcase
  teacher_last_name?: string | null,
  // eslint-disable-next-line camelcase
  expires_at?: string | null,
  // eslint-disable-next-line camelcase
  maximal_duration?: string | null,
  // eslint-disable-next-line camelcase
  session_exercises?: SessionExerciseJson[] | null,
  // eslint-disable-next-line camelcase
  current_answer_index?: number | null,
}

export default class Session {
  id: number | null;

  guid: string | null;

  open: boolean | null;

  closed: boolean | null;

  lastUpdatedAt: moment.Moment | null;

  code: string | null;

  establishmentName: string | null;

  establishmentLogoUrl: string | null;

  className: string | null;

  roomName: string | null;

  cloud: boolean | null;

  teacherFirstName: string | null;

  teacherLastName: string | null;

  expiresAt: moment.Moment | null;

  maximalDuration: moment.Duration | null;

  sessionExercises: SessionExercise[] = [];

  currentAnswerIndex: number | null;

  constructor(data: SessionJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.guid = undefined === data.guid || null === data.guid ? null : data.guid;
    this.open = undefined === data.open || null === data.open ? null : data.open;
    this.closed = undefined === data.closed || null === data.closed ? null : data.closed;
    this.lastUpdatedAt = undefined === data.last_updated_at || null === data.last_updated_at ? null : moment(data.last_updated_at);
    this.code = undefined === data.code || null === data.code ? null : data.code;
    this.establishmentName = undefined === data.establishment_name || null === data.establishment_name ? null : data.establishment_name;
    // eslint-disable-next-line max-len
    this.establishmentLogoUrl = undefined === data.establishment_logo_url || null === data.establishment_logo_url ? null : data.establishment_logo_url;
    this.className = undefined === data.class_name || null === data.class_name ? null : data.class_name;
    this.roomName = undefined === data.room_name || null === data.room_name ? null : data.room_name;
    this.cloud = undefined === data.cloud || null === data.cloud ? null : data.cloud;
    this.teacherFirstName = undefined === data.teacher_first_name || null === data.teacher_first_name ? null : data.teacher_first_name;
    this.teacherLastName = undefined === data.teacher_last_name || null === data.teacher_last_name ? null : data.teacher_last_name;
    this.expiresAt = undefined === data.expires_at || null === data.expires_at ? null : moment(data.expires_at);
    // eslint-disable-next-line max-len
    this.maximalDuration = undefined === data.maximal_duration || null === data.maximal_duration ? null : moment.duration(data.maximal_duration);
    // eslint-disable-next-line max-len
    this.currentAnswerIndex = undefined === data.current_answer_index || null === data.current_answer_index ? null : data.current_answer_index;

    if (undefined === data.session_exercises || null === data.session_exercises) {
      this.sessionExercises = [];
    } else {
      this.sessionExercises = data.session_exercises.map((sessionExercise) => new SessionExercise(sessionExercise));
    }
  }

  get teacherFullName(): string {
    return `${this.teacherFirstName} ${this.teacherLastName}`;
  }

  toJSON(): SessionJson {
    return {
      id: this.id,
      guid: this.guid,
      open: this.open,
      closed: this.closed,
      last_updated_at: this.lastUpdatedAt?.toISOString(),
      code: this.code,
      establishment_name: this.establishmentName,
      establishment_logo_url: this.establishmentLogoUrl,
      class_name: this.className,
      room_name: this.roomName,
      cloud: this.cloud,
      teacher_first_name: this.teacherFirstName,
      teacher_last_name: this.teacherLastName,
      expires_at: this.expiresAt?.toISOString(),
      maximal_duration: this.maximalDuration?.toISOString(),
      session_exercises: this.sessionExercises.map((sessionExercise: SessionExercise): SessionExerciseJson => sessionExercise.toJSON()),
      current_answer_index: this.currentAnswerIndex,
    };
  }
}
