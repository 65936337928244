import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  I18N_FALLBACK_LOCALE,
} from '../constants';

Vue.use(VueI18n);

function loadLocaleMessages(): VueI18n.LocaleMessages {
  const locales = require.context('../../translations', true, /\w+\.\w{2}\.yaml$/i);
  const messages = {};

  locales.keys().forEach((key: string): void => {
    const matches = key.match(/(?<domain>\w+)\.(?<locale>\w{2})\.yaml/i);

    if (null === matches) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { domain, locale } = matches.groups as any;

    if (!(locale in messages)) {
      messages[locale] = {};
    }

    messages[locale][domain] = locales(key);
  });

  return messages;
}

const messages: VueI18n.LocaleMessages = loadLocaleMessages();
export const availableLocales = Object.getOwnPropertyNames(messages);

const i18n = new VueI18n({
  locale: I18N_FALLBACK_LOCALE,
  fallbackLocale: I18N_FALLBACK_LOCALE,
  messages,
});

export default i18n;
