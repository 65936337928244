import Vue, { VNode } from 'vue';
import Component from 'vue-class-component';

@Component
export default class ToastMixin extends Vue {
  private translate(translationKey: string, fallback?: string): string {
    const translatedMessage = this.$t(translationKey) as string;

    if ('' === translatedMessage || translationKey === translatedMessage) {
      return undefined === fallback ? translationKey : fallback;
    }

    return translatedMessage;
  }

  displayError(title: string, message: string): void {
    const translationKey = `error.${message}`;

    this.$root.$bvToast.toast(this.translate(translationKey, message), {
      title: [
        this.$createElement('fa-icon', {
          class: 'mr-1',
          props: {
            icon: 'times-circle',
          },
        }),
        { text: this.translate(`toast.app.error.${title}`) as string } as VNode,
      ],
      autoHideDelay: 5000,
      variant: 'danger',
    });
  }

  displaySuccess(title: string, message: string): void {
    this.$root.$bvToast.toast(this.translate(message), {
      title: [
        this.$createElement('fa-icon', {
          class: 'mr-1',
          props: {
            icon: 'check-circle',
          },
        }),
        { text: this.translate(`toast.app.success.${title}`) as string } as VNode,
      ],
      autoHideDelay: 2000,
      variant: 'success',
    });
  }
}
