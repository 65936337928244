
import { Component as VueComponent } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import SessionHeader from './header/SessionHeader.vue';
import SimpleHeader from './header/SimpleHeader.vue';
import HeaderKind from '../plugins/navigation/model/HeaderKind';
import Route from '../plugins/navigation/model/Route';

@Component
export default class Header extends Vue {
  get headerComponent(): VueComponent {
    if (HeaderKind.SESSION === this.currentRoute.options.headerKind) {
      return SessionHeader;
    }

    return SimpleHeader;
  }

  get currentRoute(): Route {
    return this.$navigation.currentRoute;
  }
}
