
import { mixins } from 'vue-class-component';
import {
  Component,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ToastMixin from '../mixins/toast';
import ExerciseMedia from '../model/exerciseMedia';

@Component
export default class Player extends mixins(ToastMixin) {
  @Prop({ default: '' })
  readonly classType!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  isPlaying = false;

  @Prop({ default: null })
  readonly media!: ExerciseMedia | null;

  @Prop({ default: null })
  readonly src!: string | null;

  @Getter('oauth/accessToken')
    accessToken!: string | null;

  @Ref()
  readonly player!: HTMLAudioElement;

  get buttonClass(): string {
    return this.isPlaying ? `btn-outline-${this.classType}` : `btn-${this.classType}`;
  }

  get buttonIcon(): string {
    return this.isPlaying ? 'pause' : 'play';
  }

  get hasMedia(): boolean {
    return null !== this.media || null !== this.src;
  }

  @Watch('src')
  reloadMedia(): void {
    this.player.load();
  }

  playStarted(): void {
    this.isPlaying = true;
  }

  playStopped(): void {
    this.isPlaying = false;
  }

  startPlayback(): void {
    if (!this.player) {
      return;
    }

    this.player.play()
      .catch((error: Error): void => {
        this.displayError('generic', error.message);
      });
  }

  startStopPlayback(): void {
    if (this.isPlaying) {
      this.stopPlayback();
    } else {
      this.startPlayback();
    }
  }

  stopPlayback(): void {
    if (!this.player) {
      return;
    }

    if (!this.player.paused) {
      this.player.pause();
    }
  }
}
