import moment from 'moment';
import {
  GetterTree,
  MutationTree,
} from 'vuex';

import RootState from '../rootState';

export const STORE_TOKEN = 'STORE_TOKEN';

class OAuthState {
  accessToken: string | null = null;

  accessTokenExpiresAt: string | null = null;

  refreshToken: string | null = null;

  refreshTokenExpiresAt: string | null = null;
}

const getters: GetterTree<OAuthState, RootState> = {
  accessToken: (state: OAuthState, { accessTokenExpiresAt }): string | null => {
    const accessTokenEmpty = null === state.accessToken;
    const accessTokenExpiresAtEmpty = null === state.accessTokenExpiresAt;

    return accessTokenEmpty
    || accessTokenExpiresAtEmpty
    || accessTokenExpiresAt.isBefore() ? null : state.accessToken;
  },
  accessTokenExpiresAt: (state: OAuthState): moment.Moment | null => {
    if (null === state.accessTokenExpiresAt) {
      return null;
    }

    return moment(state.accessTokenExpiresAt);
  },
  hasAccessToken: (state: OAuthState, { accessToken }): boolean => null !== accessToken,
};

const mutations: MutationTree<OAuthState> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [STORE_TOKEN](state: OAuthState, values: any): void {
    if ('access_token' in values && 'expires_in' in values) {
      state.accessToken = values.access_token;
      state.accessTokenExpiresAt = moment().add(values.expires_in, 'seconds').toISOString();

      if ('refresh_token' in values && 'refresh_token_lifetime' in values) {
        state.refreshToken = values.refresh_token;
        state.refreshTokenExpiresAt = moment().add(values.refresh_token_lifetime, 'seconds').toISOString();
      } else {
        state.refreshToken = null;
        state.refreshTokenExpiresAt = null;
      }
    }
  },
};

export default {
  namespaced: true,
  state: new OAuthState(),
  getters,
  mutations,
};
