
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import {
  Getter,
  Action,
} from 'vuex-class';

import ZeroPad from '../mixins/zeropad';
import Session from '../model/session';
import { SESSION_LOGIN, SESSION_STUDENT_INFO } from '../plugins/navigation/routes';

@Component
export default class SessionInfo extends mixins(ZeroPad) {
  @Getter('session/session')
    session!: Session | null;

  @Action('session/finishSession')
    finishSession;

  mounted(): void {
    if (null === this.session) {
      this.backToSessionLoginPage();
    }
  }

  backToSessionLoginPage(): void {
    this.finishSession();
    this.$navigation.navigate(SESSION_LOGIN);
  }

  get formattedSessionMaximalDuration(): string {
    const maximalDuration = this.session?.maximalDuration;

    if (null === maximalDuration || undefined === maximalDuration) {
      return '';
    }

    return [
      'hours',
      'minutes',
      'seconds',
    ].map((unit: string): string => {
      const length = maximalDuration[unit]();

      return this.zeroPad(length, 2);
    }).join(':');
  }

  goToInfoStudentPage(): void {
    if (!this.sessionIsExpired) {
      this.$navigation.navigate(SESSION_STUDENT_INFO);
    } else {
      this.backToSessionLoginPage();
    }
  }

  get sessionIsExpired(): boolean {
    if (null === this.session || null === this.session.expiresAt) {
      return false;
    }

    return this.session.expiresAt.isSameOrBefore(moment());
  }

  get sessionExpirationIsClose(): boolean {
    if (null === this.session || null === this.session.expiresAt) {
      return false;
    }

    return !this.sessionIsExpired && this.session.expiresAt.isSameOrBefore(moment().add(15, 'minutes'));
  }
}
