import Vue from 'vue';
import Storage from 'vue-ls';
import {
  ActionContext,
  ActionTree,
  GetterTree,
  MutationTree,
} from 'vuex';
import moment from 'moment/moment';
import api from '../../plugins/api';
import { availableLocales } from '../../plugins/i18n';
import RootState from '../rootState';

Vue.use(Storage);

export const SET_LANGUAGE = 'SET_LANGUAGE';

class LanguageState {
  language: string | null;

  constructor() {
    this.language = Vue.ls.get('language');
  }
}

type LanguageContext = ActionContext<LanguageState, RootState>;

const getters: GetterTree<LanguageState, RootState> = {
  language: (state: LanguageState): string | null => state.language,
};

const mutations: MutationTree<LanguageState> = {
  [SET_LANGUAGE](state: LanguageState, language: string): void {
    Vue.ls.set('language', language);
    api.setLanguage(language);
    moment.locale(language);
    state.language = language;
  },
};

const actions: ActionTree<LanguageState, RootState> = {
  setLanguage({ commit }: LanguageContext, languages: string | string[]): void {
    if ('string' === typeof languages) {
      commit(SET_LANGUAGE, languages);
    } else {
      const requestedLocales = languages.map((requestedLanguage: string): string => requestedLanguage.split('-')[0]);
      // eslint-disable-next-line max-len
      const language = requestedLocales.find((requestedLanguage: string): boolean => availableLocales.some((availableLocale: string): boolean => 0 < requestedLanguage.split(new RegExp(availableLocale, 'gi')).length - 1));

      commit(SET_LANGUAGE, language || null);
    }
  },
};

export default {
  namespaced: true,
  state: new LanguageState(),
  getters,
  mutations,
  actions,
};
