import ExerciseMedia, { ExerciseMediaJson } from './exerciseMedia';
import QuestionChoice, { QuestionChoiceJson } from './questionChoice';

export interface QuestionJson {
  id?: number | null,
  guid?: string | null,
  position?: number | null,
  media?: ExerciseMediaJson | null,
  // eslint-disable-next-line camelcase
  question_content?: string | null,
  // eslint-disable-next-line camelcase
  exercise_question_choices?: QuestionChoiceJson[] | null,
}

export default class Question {
  id: number | null;

  guid: string | null;

  position: number | null;

  media: ExerciseMedia | null;

  questionContent: string | null;

  exerciseQuestionChoices: QuestionChoice[] | null;

  constructor(data: QuestionJson = {}) {
    this.id = undefined === data.id || null === data.id ? null : data.id;
    this.guid = undefined === data.guid || null === data.guid ? null : data.guid;
    this.position = undefined === data.position || null === data.position ? null : data.position;
    this.media = undefined === data.media || null === data.media ? null : new ExerciseMedia(data.media);
    this.questionContent = undefined === data.question_content || null === data.question_content ? null : data.question_content;

    if (null === data.exercise_question_choices || undefined === data.exercise_question_choices) {
      this.exerciseQuestionChoices = [];
    } else {
      this.exerciseQuestionChoices = data.exercise_question_choices.map((choice) => new QuestionChoice(choice));
    }
  }

  get isAudio(): boolean {
    return null !== this.media;
  }

  get isText(): boolean {
    return null !== this.questionContent;
  }

  toJSON(): QuestionJson {
    return {
      id: this.id,
      guid: this.guid,
      position: this.position,
      media: null === this.media ? null : this.media.toJSON(),
      question_content: this.questionContent,
      exercise_question_choices: null === this.exerciseQuestionChoices
        ? null : this.exerciseQuestionChoices.map((choice: QuestionChoice): QuestionChoiceJson => choice.toJSON()),
    };
  }
}
