
import { mixins } from 'vue-class-component';
import { Component, Ref } from 'vue-property-decorator';
import { VueSvgGauge } from 'vue-svg-gauge';
import { Action, Getter } from 'vuex-class';
import moment from 'moment';

import colors from '../assets/scss/_exports.scss';
import ExerciseQuestion from '../components/exercise/ExerciseQuestion.vue';
import ExerciseSubject from '../components/exercise/ExerciseSubject.vue';
import ToastMixin from '../mixins/toast';
import ZeroPad from '../mixins/zeropad';
import { isAudio as isAudioAnswer, isText as isTextAnswer } from '../model/answerKind';
import Exercise from '../model/exercise';
import { isAudioRepeat } from '../model/exerciseKind';
import {
  isAudio as isAudioMedia,
  isImage as isImageMedia,
  isPdf as isPdfMedia,
  isText as isTextMedia,
  isVideo as isVideoMedia,
} from '../model/mediaKind';
import Question from '../model/question';
import Session from '../model/session';
import Student from '../model/student';
import StudentSession from '../model/studentSession';
import Countdown from '../plugins/countdown';
import {
  RESUME_SESSION,
  SESSION_EXERCISE_SUMMARY,
  SESSION_LOGIN,
  SESSION_SUMMARY,
  SESSION_TIMER_EXPIRED,
} from '../plugins/navigation/routes';

const TRANSITION_SLIDE_NEXT = 'slide-next';
const TRANSITION_SLIDE_PREVIOUS = 'slide-previous';

@Component({
  components: {
    ExerciseQuestion,
    ExerciseSubject,
    VueSvgGauge,
  },
})
export default class SessionExerciseQuestion extends mixins(ToastMixin, ZeroPad) {
  transitionName = TRANSITION_SLIDE_NEXT;

  countdown = new Countdown();

  @Getter('session/session')
    session!: Session | null;

  @Getter('student/student')
    student!: Student | null;

  @Getter('session/currentExercise')
    exercise!: Exercise | null;

  @Getter('session/studentSession')
    studentSession!: StudentSession | null;

  @Getter('exercise/currentQuestion')
    currentQuestion!: Question | null;

  @Getter('exercise/currentQuestionIndex')
    currentQuestionIndex!: number | null;

  @Getter('session/currentAnswer')
    currentAnswer;

  @Getter('session/hasNextExercise')
    hasNextExercise!: boolean;

  @Getter('exercise/hasNextQuestion')
    hasNextQuestion!: boolean;

  @Getter('session/hasPreviousExercise')
    hasPreviousExercise!: boolean;

  @Getter('exercise/hasPreviousQuestion')
    hasPreviousQuestion!: boolean;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Getter('session/progressionCurrent')
    progressionCurrent!: number;

  @Getter('session/progressionMax')
    progressionMax!: number;

  @Action('session/loadNextExercise')
    nextExercise;

  @Action('session/loadNextQuestion')
    nextQuestion;

  @Action('session/loadPreviousQuestion')
    previousQuestion;

  @Action('applicationState/toggleNavigationDisabled')
    toggleNavigationDisabled;

  @Action('session/uploadCurrentAnswer')
    uploadCurrentAnswer;

  @Ref()
  readonly question!: ExerciseQuestion;

  get exerciseDirectives(): string | null {
    if (null === this.exercise) {
      return null;
    }

    if (isAudioRepeat(this.exercise)) {
      return this.$t('messages.app.directives.audio_repeat') as string;
    }

    if (isAudioMedia(this.exercise)) {
      if (isAudioAnswer(this.exercise)) {
        return this.$t('messages.app.directives.audio_quiz_audio_answer') as string;
      }

      if (isTextAnswer(this.exercise)) {
        return this.$t('messages.app.directives.audio_quiz_text_answer') as string;
      }
    }

    if (isImageMedia(this.exercise)) {
      if (isAudioAnswer(this.exercise)) {
        return this.$t('messages.app.directives.image_quiz_audio_answer') as string;
      }

      if (isTextAnswer(this.exercise)) {
        return this.$t('messages.app.directives.image_quiz_text_answer') as string;
      }
    }

    if (isPdfMedia(this.exercise) || isTextMedia(this.exercise)) {
      if (isAudioAnswer(this.exercise)) {
        return this.$t('messages.app.directives.text_quiz_audio_answer') as string;
      }

      if (isTextAnswer(this.exercise)) {
        return this.$t('messages.app.directives.text_quiz_text_answer') as string;
      }
    }

    if (isVideoMedia(this.exercise)) {
      if (isAudioAnswer(this.exercise)) {
        return this.$t('messages.app.directives.video_quiz_audio_answer') as string;
      }

      if (isTextAnswer(this.exercise)) {
        return this.$t('messages.app.directives.video_quiz_text_answer') as string;
      }
    }

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  get gaugeColor(): string {
    return colors.primary;
  }

  // eslint-disable-next-line class-methods-use-this
  get gaugeDefaultColor(): string {
    return colors['gray-100'];
  }

  mounted(): void {
    this.redirect();
    this.initializeSession();

    if (
      null !== this.studentSession?.startedAt
      && undefined !== this.studentSession?.startedAt
      && null !== this.session?.maximalDuration
      && undefined !== this.session?.maximalDuration
    ) {
      this.countdown.start(this.studentSession?.startedAt, this.session?.maximalDuration, (): void => {
        this.maximalDurationReached();
      });
    }
  }

  beforeUnmount(): void {
    this.countdown.stop();
  }

  initializeSession(): void {
    if (null === this.exercise) {
      this.nextExercise();
    }
  }

  redirect(): void {
    if (null === this.session || null === this.student) {
      this.$navigation.navigate(SESSION_LOGIN);
    }

    if (this.session?.expiresAt?.isSameOrBefore(moment())) {
      this.$navigation.navigate(RESUME_SESSION);
    }
  }

  maximalDurationReached(): void {
    this.$navigation.navigate(SESSION_TIMER_EXPIRED);
  }

  navigateNextQuestion(): void {
    this.transitionName = TRANSITION_SLIDE_NEXT;
    this.nextQuestion();
  }

  navigatePreviousQuestion(): void {
    this.transitionName = TRANSITION_SLIDE_PREVIOUS;
    this.previousQuestion();
  }

  uploadAnswer(callbackName: string): void {
    this.toggleNavigationDisabled(true);

    this.uploadCurrentAnswer()
      .then(() => {
        this[callbackName]();
      })
      .catch((error: Error) => {
        this.displayError('generic', error.message);
      }).finally((): void => {
        this.toggleNavigationDisabled(false);
      });
  }

  stopQuestionAndAnswer(): void {
    this.question.finishInteraction();
  }

  changeQuestionClicked(callbackName: string): void {
    if (this.isNavigationDisabled) {
      return;
    }

    this.stopQuestionAndAnswer();
    this.uploadAnswer(callbackName);
  }

  startNextExercise(): void {
    this.nextQuestion();
    this.$navigation.navigate(SESSION_EXERCISE_SUMMARY);
  }

  finishSession(): void {
    this.$navigation.navigate(SESSION_SUMMARY);
  }
}
