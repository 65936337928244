
import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Player from '../../Player.vue';
import Question from '../../../model/question';

@Component({
  components: {
    Player,
  },
})
export default class AudioQuestion extends Vue {
  @Prop()
    question!: Question;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Ref()
  readonly player!: Player;

  finishInteraction(): void {
    this.player.stopPlayback();
  }
}
