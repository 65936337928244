
import { Component, Ref, Vue } from 'vue-property-decorator';
import {
  Getter,
  Action,
} from 'vuex-class';

import Player from './Player.vue';
import RecorderPlugin from '../plugins/recorder';

@Component({
  components: {
    Player,
  },
})
export default class Recorder extends Vue {
  show = false;

  error: string | null = null;

  recorder = new RecorderPlugin();

  @Getter('session/currentAnswer')
    currentAnswer;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Action('session/storeAnswer')
    storeAnswer;

  @Action('applicationState/toggleNavigationDisabled')
    toggleNavigationDisabled;

  @Ref()
  readonly playerComponent!: Player;

  get hasAnswer(): boolean {
    return null !== this.currentAnswer;
  }

  record(): void {
    this.toggleNavigationDisabled();
    this.$emit('recordingStarted');

    this.recorder
      .startRecording()
      .then((audioTrack) => {
        this.storeAnswer(audioTrack)
          .then(() => {
            this.toggleNavigationDisabled();
          });
      })
      .catch((err) => {
        this.show = true;
        this.error = err.message;
        this.toggleNavigationDisabled();
      });
  }

  stop(): void {
    this.recorder.finishRecording();
  }

  stopAnswer(): void {
    this.playerComponent.stopPlayback();
  }
}
