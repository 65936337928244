
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import {
  Getter,
  Action,
} from 'vuex-class';

import SessionSidebar from '../components/header/SessionSidebar.vue';
import ToastMixin from '../mixins/toast';
import Session from '../model/session';
import StudentSession from '../model/studentSession';
import { SESSION_EXERCISE_QUESTION, SESSION_LOGIN } from '../plugins/navigation/routes';
import * as MediaKind from '../model/mediaKind';
import ExerciseArchive from '../model/exerciseArchive';
import SessionExercise from '../model/sessionExercise';

@Component({
  components: {
    SessionSidebar,
  },
})
export default class SessionSummary extends mixins(ToastMixin) {
  show = false;

  @Getter('session/session')
    session!: Session | null;

  @Getter('session/studentSession')
    studentSession!: StudentSession | null;

  @Getter('session/answers')
    answers!: {
    [key: string]: Blob,
  };

  @Action('session/finishSession')
    finishSession;

  @Action('session/loadExercise')
    loadExercise;

  @Action('applicationState/toggleSidebar')
    toggleSidebar;

  @Action('session/uploadCurrentAnswer')
    uploadCurrentAnswer;

  mounted(): void {
    this.redirect();
  }

  static isAudio(exercise: ExerciseArchive): boolean {
    return MediaKind.isAudio(exercise);
  }

  static isImage(exercise: ExerciseArchive): boolean {
    return MediaKind.isImage(exercise);
  }

  static isVideo(exercise: ExerciseArchive): boolean {
    return MediaKind.isVideo(exercise);
  }

  // eslint-disable-next-line class-methods-use-this
  getExerciseIcon(exercise: ExerciseArchive): string {
    if (SessionSummary.isVideo(exercise)) {
      return 'film';
    }

    if (SessionSummary.isAudio(exercise)) {
      return 'headphones';
    }

    if (SessionSummary.isImage(exercise)) {
      return 'image';
    }

    return 'book';
  }

  // eslint-disable-next-line class-methods-use-this
  getExerciseClass(exercise: ExerciseArchive): string {
    if (SessionSummary.isVideo(exercise)) {
      return 'text-media-video';
    }

    if (SessionSummary.isAudio(exercise)) {
      return 'text-media-audio';
    }

    if (SessionSummary.isImage(exercise)) {
      return 'text-media-image';
    }

    return 'text-media-text';
  }

  redirect(): void {
    if (null === this.session || null === this.studentSession) {
      this.$navigation.navigate(SESSION_LOGIN);
    }
  }

  numberOfAnswer(exercise: SessionExercise): number {
    const exerciseGuid = exercise.exerciseArchive?.guid;

    if (!exerciseGuid) {
      return 0;
    }

    const regex = new RegExp(`^${exerciseGuid}/`);

    return Object.keys(this.answers).filter((key) => regex.test(key)).length;
  }

  endStudentSession(event: Event): void {
    event.preventDefault();

    const startedAt = this.studentSession?.startedAt;
    const sessionData: {
      duration: number | null,
    } = {
      duration: null,
    };

    if (null !== startedAt && undefined !== startedAt) {
      sessionData.duration = Math.round(moment.duration(moment().diff(moment(startedAt))).asMilliseconds());
    }

    this
      .uploadCurrentAnswer()
      .then(() => this.$api.put(`session/${this.studentSession?.guid}`, sessionData))
      .then(() => {
        this.finishSession();
        this.$navigation.navigate(SESSION_LOGIN);
      })
      .catch((error) => {
        this.displayError('generic', error.message);
      });
  }

  navigateToExercise(exercise: string): void {
    this.loadExercise(exercise);

    if (SESSION_EXERCISE_QUESTION !== this.$navigation.currentRoute.name) {
      this.$navigation.navigate(SESSION_EXERCISE_QUESTION);
    }

    this.toggleSidebar(false);
  }
}
