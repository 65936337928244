
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { availableLocales } from '../plugins/i18n';

@Component
export default class LocaleSwitcher extends Vue {
  @Getter('language/language')
    currentLanguage;

  @Action('language/setLanguage')
    setLanguage;

  // eslint-disable-next-line class-methods-use-this
  get availableLocales(): string[] {
    return availableLocales.filter((locale: string): boolean => locale !== this.currentLanguage);
  }

  get formattedCurrentLanguage(): string {
    return this.currentLanguage?.toString()?.toUpperCase();
  }

  // eslint-disable-next-line class-methods-use-this
  languageName(language: string): string {
    const intl = new Intl.DisplayNames([language], {
      type: 'language',
    });
    const languageName = intl.of(language);

    return `${languageName?.substring(0, 1).toUpperCase()}${languageName?.substring(1)}`;
  }
}
