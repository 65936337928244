import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/index';
import i18n from '../plugins/i18n';

Vue.use(VueRouter);

const Router = new VueRouter({
  mode: 'history',
  routes,
});

Router.beforeEach(async (to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const qsLocaleMatch: any = window.location.search.match(/(\?|&)_locale=(?<locale>\w{2}(-\w{2})?)(&|$)/);

  if (null !== qsLocaleMatch) {
    const requestedLocale = qsLocaleMatch.groups.locale;

    await store.restored;
    store.dispatch('language/setLanguage', requestedLocale);
    window.location.search = window.location.search.replace(qsLocaleMatch[0], '');
    return;
  }

  await store.restored;
  const currentLanguage = store.getters['language/language'];

  if (currentLanguage && store.getters['language/language'] !== i18n.locale) {
    i18n.locale = currentLanguage;
    next();
  } else if (!currentLanguage) {
    store.dispatch('language/setLanguage', navigator.languages)
      .then(() => {
        i18n.locale = store.getters['language/language'];
        next();
      });
  } else {
    next();
  }
});

export default Router;
