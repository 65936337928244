
import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import AudioQuestion from '../question/AudioQuestion.vue';
import TextQuestion from '../question/TextQuestion.vue';
import Recorder from '../../Recorder.vue';
import Question from '../../../model/question';

@Component({
  components: {
    AudioQuestion,
    TextQuestion,
    Recorder,
  },
})
export default class AudioAnswer extends Vue {
  @Prop()
    question!: Question;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Ref()
  readonly audioQuestion!: AudioQuestion;

  @Ref()
  readonly recorder!: Recorder;

  finishInteraction(): void {
    if (this.audioQuestion) {
      this.audioQuestion.finishInteraction();
    }

    this.recorder.stop();
    this.recorder.stopAnswer();
  }
}
