import i18n from './i18n';

const mediaConstraints = {
  audio: true,
};

const mimeTypes = [
  'audio/ogg;codecs=opus',
  'audio/ogg',
  'audio/webm;codecs=opus',
  'audio/webm',
];

export default class Recorder {
  #mediaRecorder: MediaRecorder | null = null;

  static get isSupported(): boolean {
    return navigator.mediaDevices && 'function' === typeof navigator.mediaDevices.getUserMedia && 'undefined' !== typeof MediaRecorder;
  }

  startRecording(): Promise<Blob | null> {
    if (!Recorder.isSupported) {
      return Promise.reject(i18n.t('error.app.webrtc_not_supported'));
    }

    return new Promise((resolve, reject): void => {
      navigator.mediaDevices.getUserMedia(mediaConstraints)
        .then((stream: MediaStream): void => {
          const mimeType = mimeTypes.find((candidate: string) => MediaRecorder.isTypeSupported(candidate));
          const audioChunks: Blob[] = [];

          const mediaRecorder = new MediaRecorder(stream, { mimeType });

          mediaRecorder.ondataavailable = (e: BlobEvent) => {
            audioChunks.push(e.data);
          };

          mediaRecorder.onstop = () => {
            resolve(0 < audioChunks.length ? new Blob(audioChunks, {
              type: mediaRecorder.mimeType,
            }) : null);
          };

          mediaRecorder.start();

          if (null !== this.#mediaRecorder) {
            this.#mediaRecorder.stop();
          }

          this.#mediaRecorder = mediaRecorder;
        })
        .catch((err) => {
          let message = 'Unknown error';
          if ('AbortError' === err.name) {
            message = i18n.t('error.app.recording_error') as string;
          } else if ('NotAllowedError' === err.name || 'SecurityError' === err.name) {
            message = i18n.t('error.app.microphone_not_allowed') as string;
          } else if ('NotFoundError' === err.name) {
            message = i18n.t('error.app.microphone_not_found') as string;
          } else if ('NotReadableError' === err.name) {
            message = i18n.t('error.app.microphone_locked') as string;
          } else if ('OverConstrainedError' === err.name || 'TypeError' === err.name) {
            message = 'Invalid constraint';
          }

          reject(new Error(
            message,
          ));
        });
    });
  }

  finishRecording(): void {
    if (null !== this.#mediaRecorder) {
      this.#mediaRecorder.stop();
      this.#mediaRecorder = null;
    }
  }
}
