import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'vuex';
import {
  APP_VERSION,
  BACKEND_BASE_URL,
  BACKEND_CLIENT_ID,
  BACKEND_CLIENT_SECRET,
  BACKEND_SCOPES,
  BACKEND_TOKEN_URL,
} from '../constants';
import { STORE_TOKEN } from '../store/modules/oauth';
import ExerciseMedia, { ExerciseMediaJson } from '../model/exerciseMedia';

export interface RequestParameters extends AxiosRequestConfig {
  forceRefreshToken?: boolean;
  noAuth?: boolean;
}

export class Api {
  #language: string | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #store: Store<any> | null = null;

  setLanguage(language: string): void {
    this.#language = language;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStore(store: Store<any>): void {
    this.#store = store;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(url: string, options: RequestParameters = {}): Promise<any> {
    return this.request({
      url,
      method: 'DELETE',
      ...options,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(url: string, options: RequestParameters = {}): Promise<any> {
    return this.request({
      url,
      method: 'GET',
      ...options,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post(url: string, data: unknown, options: RequestParameters = {}): Promise<any> {
    return this.request({
      url,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    } as RequestParameters);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put(url: string, data: unknown, options: RequestParameters = {}): Promise<any> {
    return this.request({
      url,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    } as RequestParameters);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadFile(url: string, fields: { [key: string]: Blob }, options: RequestParameters = {}): Promise<any> {
    const form = new FormData();

    Object.keys(fields).forEach((field) => {
      form.append(field, fields[field]);
    });

    return this.request({
      url,
      method: 'POST',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...options,
    } as RequestParameters);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async request(params: RequestParameters, isRetry = false): Promise<any> {
    if (!('url' in params)) {
      return Promise.reject(new Error('Missing `url` in api call'));
    }

    const options: AxiosRequestConfig = params;

    if ('string' === typeof options.url && !options.url.match(/^[a-z]+:\/\//)) {
      options.url = `${BACKEND_BASE_URL}${options.url.replace(/^\//, '')}`;
    }

    if (!('headers' in options) || undefined === options.headers) {
      options.headers = {
        Accept: 'application/json',
        'LP-Player-Version': APP_VERSION,
      };
    }

    options.headers['Accept-Language'] = this.#language;

    const accessToken = await this.getAccessToken(options);

    if (null !== accessToken) {
      options.headers.Authorization = `Bearer ${accessToken}`;
    }

    return axios
      .request(options)
      .then(({ data }): unknown => {
        if ('string' === typeof data) {
          try {
            return JSON.parse(data);
          } catch (e) {
            return null;
          }
        }

        return data;
      }).catch((error) => {
        if ('response' in error) {
          const { response } = error;
          const { data, status } = response;

          if (401 === status && !isRetry) {
            return this.getAccessToken({
              forceRefreshToken: true,
            }).then(() => this.request(params, true));
          }

          if (400 === status) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const validationError: any = new Error(
              data.message
              || response.statusText
              || ('error' in data ? data.error.message : false)
              || error.message,
            );

            if ('errors' in data) {
              const { errors } = data;

              validationError.errors = errors;
            }

            throw validationError;
          }
        }

        throw error;
      });
  }

  async getAccessToken(options: RequestParameters = {}): Promise<string | null> {
    if (true === options.noAuth) {
      return null;
    }

    if (this.#store?.getters['oauth/hasAccessToken'] && true !== options.forceRefreshToken) {
      return this.#store?.state.oauth.accessToken;
    }

    if (this.#store?.getters['oauth/hasRefreshToken']) {
      const accessToken = await axios.post(BACKEND_TOKEN_URL, {
        grant_type: 'refresh_token',
        refresh_token: this.#store?.state.oauth.refreshToken,
      }).then((response) => {
        if (200 === response.status && 'access_token' in response.data) {
          this.#store?.commit(`oauth/${STORE_TOKEN}`, response.data);

          return response.data.access_token;
        }

        return null;
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);

        return null;
      });

      if (null === accessToken) {
        // eslint-disable-next-line no-console
        console.error('Unable to refresh access token');
      }

      return accessToken as string;
    }

    interface AccessTokenResponse {
      // eslint-disable-next-line camelcase
      access_token: string;
    }

    const accessToken = await axios.post(BACKEND_TOKEN_URL, {
      grant_type: 'client_credentials',
      client_id: BACKEND_CLIENT_ID,
      client_secret: BACKEND_CLIENT_SECRET,
      scopes: BACKEND_SCOPES,
    }).then((response: AxiosResponse<AccessTokenResponse>) => {
      if (200 === response.status && 'access_token' in response.data) {
        this.#store?.commit(`oauth/${STORE_TOKEN}`, response.data);

        return response.data.access_token;
      }

      return null;
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);

      return null;
    });

    if (null === accessToken) {
      // eslint-disable-next-line no-console
      console.error('Unable to get access token');
    }

    return accessToken;
  }

  // eslint-disable-next-line class-methods-use-this
  buildMediaUrl(media: ExerciseMedia | ExerciseMediaJson | null, accessToken: string | null): string | null {
    const url = media?.url;

    if (!url || !accessToken) {
      return null;
    }

    const mediaUrl = new URL(url);
    mediaUrl.searchParams.set('access_token', accessToken);

    return mediaUrl.href;
  }
}

export default new Api();
