
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import {
  Getter,
  Action,
} from 'vuex-class';

import SessionExercise from '../model/sessionExercise';
import { SESSION_EXERCISE_QUESTION, SESSION_LOGIN } from '../plugins/navigation/routes';
import Countdown from '../plugins/countdown';

@Component
export default class SessionExerciseSummary extends Vue {
  countdown = new Countdown();

  @Getter('session/currentExercise')
    currentExercise;

  @Getter('session/session')
    session;

  @Getter('session/sessionExercises')
    sessionExercises;

  @Getter('session/studentSession')
    studentSession;

  @Getter('exercise/isAudio')
    isAudio;

  @Getter('exercise/isImage')
    isImage;

  @Getter('exercise/isVideo')
    isVideo;

  @Action('session/loadNextExercise')
    nextExercise;

  get exerciseIcon(): string {
    if (this.isVideo) {
      return 'film';
    }

    if (this.isAudio) {
      return 'headphones';
    }

    if (this.isImage) {
      return 'image';
    }

    return 'book';
  }

  get exercisePosition(): number | null {
    // eslint-disable-next-line max-len
    const exercise = this.sessionExercises.find((sessionExercise: SessionExercise): boolean => sessionExercise.exerciseArchive?.guid === this.currentExercise?.guid);

    return exercise ? exercise.position : null;
  }

  get sessionIsExpired(): boolean {
    if (null === this.session || null === this.session.expiresAt) {
      return false;
    }

    return this.session.expiresAt.isSameOrBefore(moment());
  }

  get sessionExpirationIsClose(): boolean {
    if (null === this.session || null === this.session.expiresAt) {
      return false;
    }

    return !this.sessionIsExpired && this.session.expiresAt.isSameOrBefore(moment().add(15, 'minutes'));
  }

  mounted(): void {
    if (null === this.session || null === this.studentSession) {
      this.$navigation.navigate(SESSION_LOGIN);

      return;
    }

    this.initializeSession();

    const maximalDuration = this.session?.maximalDuration;
    const startedAt = this.studentSession?.startedAt;

    if (null !== startedAt && undefined !== startedAt && null !== maximalDuration && undefined !== maximalDuration) {
      this.countdown.start(startedAt, maximalDuration);
    }
  }

  initializeSession(): void {
    if (null === this.currentExercise) {
      this.nextExercise();
    }
  }

  goToExercise(): void {
    this.$navigation.navigate(SESSION_EXERCISE_QUESTION);
  }
}
