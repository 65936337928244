
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import Question from '../../../model/question';

@Component
export default class TextQuestion extends Vue {
  @Prop()
    question!: Question;
}
