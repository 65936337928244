var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-content h-100"},[_c('div',{staticClass:"container h-100"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12 col-lg-8 offset-lg-2"},[_c('div',{staticClass:"d-flex flex-column h-100 align-items-stretch"},[_c('div',{staticClass:"flex-grow-1 d-flex flex-column"},[_c('p',{staticClass:"text-gray text-lg my-auto"},[_vm._v(" "+_vm._s(_vm.$t('messages.app.page.session_info.title'))+" ")])]),_c('div',{staticClass:"flex-grow-1 d-flex flex-column text-center"},[_c('dl',{staticClass:"my-auto dl-styled"},[_c('dt',[(null !== _vm.session.establishmentLogoUrl)?_c('p',{staticClass:"mb-0"},[_c('img',{staticClass:"img-fluid establishment-logo",attrs:{"src":_vm.session.establishmentLogoUrl,"alt":_vm.session.establishmentName}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('messages.app.ui.establishment'))+" ")]),_c('dd',[_vm._v(_vm._s(_vm.session.establishmentName))]),_c('dt',[_vm._v(_vm._s(_vm.$t('messages.app.ui.class')))]),_c('dd',[_vm._v(_vm._s(_vm.session.className))]),_c('dt',[_vm._v(_vm._s(_vm.$t('messages.app.ui.teacher')))]),_c('dd',[_vm._v(_vm._s(_vm.session.teacherFirstName)+" "+_vm._s(_vm.session.teacherLastName))]),_c('dt',[_vm._v(_vm._s(_vm.$t('messages.app.ui.session_code')))]),_c('dd',[_vm._v(_vm._s(_vm.session.code))]),(null !== _vm.session.expiresAt)?[_c('dt',[_vm._v(_vm._s(_vm.$t('messages.app.ui.session_expires_at')))]),_c('dd',{class:{
                    'text-danger': _vm.sessionIsExpired,
                    'text-warning': _vm.sessionExpirationIsClose,
                  }},[_vm._v(" "+_vm._s(_vm.session.expiresAt.format('L LT'))+" ")])]:_vm._e(),(null !== _vm.session.maximalDuration)?[_c('dt',[_vm._v(_vm._s(_vm.$t('messages.app.ui.session_maximal_duration')))]),_c('dd',[_vm._v(_vm._s(_vm.formattedSessionMaximalDuration))])]:_vm._e()],2)]),(_vm.sessionIsExpired)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('messages.app.page.session_info.session_is_expired'))+" ")]):_vm._e(),(_vm.sessionExpirationIsClose)?_c('div',{staticClass:"text-warning"},[_vm._v(" "+_vm._s(_vm.$t('messages.app.page.session_info.session_expires_at_is_close'))+" ")]):_vm._e(),(!_vm.session.closed)?_c('div',{staticClass:"flex-grow-1 d-flex flex-wrap align-content-center pb-3 pb-lg-0"},[_c('div',{staticClass:"col-lg"},[_c('buttonx',{staticClass:"btn btn-link text-lg font-weight-bold text-gray",attrs:{"type":"button"},on:{"click":_vm.backToSessionLoginPage}},[_vm._v(" "+_vm._s(_vm.$t('messages.app.action.change'))+" ")])],1),_c('div',{staticClass:"col-lg"},[_c('button',{staticClass:"btn btn-primary btn-block text-lg font-weight-bold",attrs:{"type":"submit","autofocus":"","disabled":_vm.sessionIsExpired},on:{"click":_vm.goToInfoStudentPage}},[_vm._v(" "+_vm._s(_vm.$t('messages.app.action.confirm'))+" ")])])]):_c('div',{class:[
              'flex-grow-1',
              'd-flex',
              'flex-wrap',
              'flex-column',
              'align-content-center',
              'justify-content-between',
              'pb-3',
              'pb-lg-0',
              'bg-warning',
              'text-light',
            ]},[_c('p',{staticClass:"my-4",domProps:{"innerHTML":_vm._s(_vm.$t('messages.app.ui.session_closed').replace(/\n/g, '<br />'))}}),_c('button',{staticClass:"btn btn-link text-lg font-weight-bold text-light",attrs:{"type":"button"},on:{"click":_vm.backToSessionLoginPage}},[_vm._v(" "+_vm._s(_vm.$t('messages.app.action.change'))+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }