
import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import AudioAnswer from './answer/AudioAnswer.vue';
import MultipleChoiceAnswer from './answer/MultipleChoiceAnswer.vue';
import TextAnswer from './answer/TextAnswer.vue';
import Exercise from '../../model/exercise';
import { isAudio, isMultipleChoice, isText } from '../../model/answerKind';
import Question from '../../model/question';

@Component({
  components: {
    AudioAnswer,
    TextAnswer,
    MultipleChoiceAnswer,
  },
})
export default class ExerciseQuestion extends Vue {
  @Prop()
    exercise!: Exercise;

  @Prop()
    question!: Question;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Ref()
    answer!: AudioAnswer | TextAnswer | MultipleChoiceAnswer;

  get isAudioAnswer(): boolean {
    return isAudio(this.exercise);
  }

  get isTextAnswer(): boolean {
    return isText(this.exercise);
  }

  get isMultipleChoiceAnswer(): boolean {
    return isMultipleChoice(this.exercise);
  }

  finishInteraction(): void {
    if (this.answer) {
      this.answer.finishInteraction();
    }
  }
}
