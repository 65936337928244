import Vue from 'vue';
import {
  ActionContext,
  ActionTree,
  GetterTree,
  MutationTree,
} from 'vuex';
import { isText } from '../../model/answerKind';
import Exercise from '../../model/exercise';
import { isMultipleChoice } from '../../model/exerciseKind';
import Question from '../../model/question';
import Session, { SessionJson } from '../../model/session';
import SessionExercise from '../../model/sessionExercise';
import StudentSession, { StudentSessionJson } from '../../model/studentSession';
import RootState from '../rootState';

class SessionState {
  session: SessionJson | null = null;

  studentSession: StudentSessionJson | null = null;

  answers: {
    [key: string]: Blob,
  } = {};

  uploadedAnswers: string[] = [];
}

type SessionContext = ActionContext<SessionState, RootState>;

interface SessionGetter {
  session: Session | null;
  studentSession: StudentSession | null;
  sessionExercises: SessionExercise[];
  progressionCurrent: number;
  progressionMax: number;
  currentExercise: Exercise | null;
  currentQuestion: Question | null;
  currentAnswer: Blob | null;
  nextExercise: Exercise | null;
  hasNextExercise: boolean;
  previousExercise: Exercise | null;
  hasPreviousExercise: boolean;
  answers: {
    [key: string]: Blob,
  };
  isAnswerUploaded: (exercise: Exercise, question: Question) => boolean;
}

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every((item: unknown): boolean => 'string' === typeof item);
}

const getters: GetterTree<SessionState, RootState> = {
  session: (state: SessionState): Session | null => (null === state.session ? null : new Session(state.session)),
  studentSession: (state: SessionState): StudentSession | null => {
    if (null === state.studentSession) {
      return null;
    }

    return new StudentSession(state.studentSession);
  },
  sessionExercises: (state: SessionState, { session }: SessionGetter): SessionExercise[] => {
    if (null === session) {
      return [];
    }

    // eslint-disable-next-line max-len
    return session.sessionExercises.sort((first: SessionExercise, second: SessionExercise): number => (first.position || 0) - (second.position || 0));
  },

  progressionCurrent: (state: SessionState, { currentExercise, sessionExercises }: SessionGetter, _rootState, rootGetters): number => {
    if (null === currentExercise) {
      return 0;
    }

    let current = 0;

    for (let exerciseIndex = 0; exerciseIndex < sessionExercises.length; exerciseIndex += 1) {
      const exercise: SessionExercise = sessionExercises[exerciseIndex];

      if (exercise.exerciseArchive?.guid === currentExercise.guid) {
        current += rootGetters['exercise/currentQuestionIndex'];
        break;
      }

      current += exercise.exerciseArchive?.questionsCount as number;
    }
    return current;
  },
  progressionMax: (state: SessionState, { sessionExercises }: SessionGetter): number => sessionExercises.reduce((
    sum: number,
    sessionExercise: SessionExercise,
  ): number => {
    const count = sessionExercise.exerciseArchive?.questionsCount as number;

    return sum + count;
  }, 0),

  // eslint-disable-next-line max-len
  currentExercise: (state: SessionState, _getters: SessionGetter, _rootState, rootGetters): Exercise | null => rootGetters['exercise/exercise'],
  // eslint-disable-next-line max-len
  currentQuestion: (state: SessionState, _getters: SessionGetter, _rootState, rootGetters): Question | null => rootGetters['exercise/currentQuestion'],
  currentAnswer: (state: SessionState, { currentExercise, currentQuestion }: SessionGetter): Blob | null => {
    if (null === currentExercise || null === currentQuestion) {
      return null;
    }

    const key = `${currentExercise.guid}/${currentQuestion.guid}`;

    return key in state.answers ? state.answers[key] : null;
  },

  nextExercise: (state: SessionState, { currentExercise, sessionExercises }: SessionGetter): SessionExercise | null => {
    if (null === currentExercise) {
      return sessionExercises.find(() => true) || null;
    }

    // eslint-disable-next-line max-len
    const nextIndex = sessionExercises.findIndex((exercise: SessionExercise) => exercise.exerciseArchive?.guid === currentExercise.guid) + 1;

    return nextIndex in sessionExercises ? sessionExercises[nextIndex] : null;
  },
  hasNextExercise: (state: SessionState, { nextExercise }: SessionGetter): boolean => null !== nextExercise,
  previousExercise: (state: SessionState, { currentExercise, sessionExercises }: SessionGetter): SessionExercise | null => {
    if (null === currentExercise) {
      return sessionExercises.find(() => true) || null;
    }

    // eslint-disable-next-line max-len
    const previousIndex = sessionExercises.findIndex((exercise: SessionExercise) => exercise.exerciseArchive?.guid === currentExercise.guid) - 1;

    return previousIndex in sessionExercises ? sessionExercises[previousIndex] : null;
  },
  hasPreviousExercise: (state: SessionState, { previousExercise }: SessionGetter): boolean => null !== previousExercise,

  answers: (state: SessionState): {
    [key: string]: Blob,
  } => state.answers,

  isAnswerUploaded: (state: SessionState) => (exercise: Exercise, question: Question): boolean => {
    const answerKey = `${exercise.guid}/${question.guid}`;

    return answerKey in state.uploadedAnswers;
  },
};

const mutations: MutationTree<SessionState> = {
  resetState(state: SessionState): void {
    Object.assign(state, new SessionState());
  },

  setSessionInfo(state: SessionState, session: Session): void {
    state.session = session;
  },

  setStudentSession(state: SessionState, studentSession: StudentSession | null): void {
    state.studentSession = null === studentSession ? null : studentSession.toJSON();
  },

  storeAnswer(state: SessionState, { exercise, question, answer }): void {
    const key = `${exercise.guid}/${question.guid}`;

    Vue.set(state.answers, key, answer);

    const idx = state.uploadedAnswers.indexOf(key);

    if (idx) {
      state.uploadedAnswers.splice(idx, 1);
    }
  },

  answerUploaded(state: SessionState, { exercise, question }): void {
    const key = `${exercise.guid}/${question.guid}`;

    state.uploadedAnswers.push(key);
  },
};

const actions: ActionTree<SessionState, RootState> = {
  finishSession({ commit }: SessionContext): void {
    commit('resetState');
    commit('applicationState/setSidebarVisible', false, {
      root: true,
    });
    commit('exercise/resetExerciseState', null, {
      root: true,
    });
  },

  setSessionInfo({ commit, dispatch }: SessionContext, session: Session): void {
    dispatch('finishSession');
    commit('setSessionInfo', session);
  },

  setStudentSession({ commit }: SessionContext, studentSession: StudentSession | null): void {
    commit('setStudentSession', studentSession);
  },

  storeAnswer({
    commit,
    getters: { currentExercise, currentQuestion },
  }: SessionContext, answer: Blob | string | string[] | null): void {
    if (
      null === answer
      || 'string' === typeof answer
      || isStringArray(answer)
    ) {
      commit('storeAnswer', {
        answer,
        exercise: currentExercise,
        question: currentQuestion,
      });

      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      commit('storeAnswer', {
        answer: reader.result,
        exercise: currentExercise,
        question: currentQuestion,
      });
    };

    reader.readAsDataURL(answer);
  },

  async uploadCurrentAnswer({
    commit,
    getters: {
      currentAnswer,
      currentExercise,
      currentQuestion,
      studentSession,
      isAnswerUploaded,
    },
  }: SessionContext): Promise<void> {
    if (null === currentAnswer || isAnswerUploaded(currentExercise, currentQuestion)) {
      return Promise.resolve();
    }

    if (isText(currentExercise) || isMultipleChoice(currentExercise)) {
      return this.$api.uploadFile(`session/${studentSession.guid}/${currentQuestion.guid}/`, {
        file: new Blob([currentAnswer], {
          type: 'text/html',
        }),
      })
        .then(() => commit('answerUploaded', {
          exercise: currentExercise,
          question: currentQuestion,
        }));
    }

    return fetch(currentAnswer)
      .then((answer: Response) => answer.blob())
      .then(async (answerBlob: Blob) => this.$api.uploadFile(`session/${studentSession.guid}/${currentQuestion.guid}/`, {
        file: answerBlob,
      }))
      .then(() => commit('answerUploaded', {
        exercise: currentExercise,
        question: currentQuestion,
      }));
  },

  loadExercise({ dispatch }: SessionContext, exercise: Exercise | string): void {
    dispatch('exercise/load', exercise, {
      root: true,
    });
  },

  loadNextExercise({ dispatch, getters: { hasNextExercise, nextExercise } }: SessionContext): void {
    if (hasNextExercise) {
      dispatch('loadExercise', nextExercise.exerciseArchive.guid);
    }
  },

  loadPreviousExercise({ dispatch, getters: { hasPreviousExercise, previousExercise } }: SessionContext): void {
    if (hasPreviousExercise) {
      dispatch('loadExercise', previousExercise.exerciseArchive.guid);
    }
  },

  loadNextQuestion({ dispatch, rootGetters }: SessionContext): void {
    if (rootGetters['exercise/hasNextQuestion']) {
      dispatch('exercise/loadNextQuestion', null, {
        root: true,
      });

      return;
    }

    dispatch('loadNextExercise');
  },

  loadPreviousQuestion({ dispatch, rootGetters }: SessionContext): void {
    if (rootGetters['exercise/hasPreviousQuestion']) {
      dispatch('exercise/loadPreviousQuestion', null, {
        root: true,
      });

      return;
    }

    dispatch('loadPreviousExercise');
  },
};

export default {
  namespaced: true,
  state: new SessionState(),
  getters,
  mutations,
  actions,
};
