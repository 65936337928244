
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import {
  Getter,
  Action,
} from 'vuex-class';

import ToastMixin from '../mixins/toast';
import ZeroPad from '../mixins/zeropad';
import Session from '../model/session';
import StudentSession from '../model/studentSession';
import { SESSION_LOGIN } from '../plugins/navigation/routes';

@Component
export default class SessionTimerExpired extends mixins(ToastMixin, ZeroPad) {
  waitSeconds = 10;

  @Getter('session/session')
    session!: Session | null;

  @Getter('session/studentSession')
    studentSession!: StudentSession | null;

  @Action('session/finishSession')
    finishSession;

  @Action('applicationState/toggleSidebar')
    toggleSidebar;

  @Action('session/uploadCurrentAnswer')
    uploadCurrentAnswer;

  mounted(): void {
    this.redirect();

    const waitInterval = setInterval(() => {
      if (0 >= this.waitSeconds) {
        clearInterval(waitInterval);
        this.endStudentSession();
      } else {
        this.waitSeconds -= 1;
      }
    }, 1000);
  }

  get formattedSessionMaximalDuration(): string {
    const maximalDuration = this.session?.maximalDuration;

    if (null === maximalDuration || undefined === maximalDuration) {
      return '';
    }

    return [
      'hours',
      'minutes',
      'seconds',
    ].map((unit: string): string => {
      const length = maximalDuration[unit]();

      return this.zeroPad(length, 2);
    }).join(':');
  }

  redirect(): void {
    if (null === this.session || null === this.studentSession) {
      this.$navigation.navigate(SESSION_LOGIN);
    }
  }

  endStudentSession(): void {
    this
      .uploadCurrentAnswer()
      .then(() => this.$api.put(`session/${this.studentSession?.guid}`, {
        duration: this.session?.maximalDuration?.asMilliseconds(),
      }))
      .then(() => {
        this.finishSession();
        this.$navigation.navigate(SESSION_LOGIN);
      })
      .catch((error) => {
        this.displayError('generic', error.message);
      });
  }
}
