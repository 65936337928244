import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component
export default class ZeroPad extends Vue {
  // eslint-disable-next-line class-methods-use-this
  zeroPad(value: number | string, length = 2): string {
    const string = `${value}`;
    const padLength = Math.max(0, length - string.length);

    if (0 === padLength) {
      return string;
    }

    return `${(new Array(padLength)).fill(0).join('')}${string}`;
  }
}
