export const HOME = 'home';
export const NOT_FOUND = 'not_found';
export const RESUME_SESSION = 'resume_session';
export const SESSION_EXERCISE_SUMMARY = 'session_exercise_summary';
export const SESSION_EXERCISE_QUESTION = 'session_exercise_question';
export const SESSION_INFO = 'session_info';
export const SESSION_LOGIN = 'session_login';
export const SESSION_STUDENT_INFO = 'session_student_info';
export const SESSION_SUMMARY = 'session_summary';
export const SESSION_TIMER_EXPIRED = 'session_timer_expired';
