import * as localForage from 'localforage';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence, { AsyncStorage } from 'vuex-persist';
// Store modules
import applicationState from './modules/applicationState';
import exercise from './modules/exercise';
import language from './modules/language';
import oauth from './modules/oauth';
import session from './modules/session';
import student from './modules/student';
import RootState from './rootState';

Vue.use(Vuex);

const indexedDb = new VuexPersistence<RootState>({
  asyncStorage: true,
  storage: localForage as AsyncStorage,
  key: 'lp',
});

const store = new Vuex.Store({
  plugins: [
    indexedDb.plugin,
  ],
  modules: {
    applicationState,
    exercise,
    language,
    oauth,
    session,
    student,
  },
});

export default store;
