// Global stylesheet
import './assets/scss/index.scss';
// Bootstrap
import { BootstrapVue } from 'bootstrap-vue';
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
// Vue.js
import Vue from 'vue';
// LocalStorage
import Storage from 'vue-ls';
// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue2';
// GTM
import VueGtm from '@gtm-support/vue2-gtm';
// Plugins
import api from './plugins/api';
import i18n from './plugins/i18n';
import navigation from './plugins/navigation/index';
import router from './router/index';
import store from './store/index';
// App
import App from './App.vue';
// Constants
import { GTM_ID } from './constants';

library.add(fas);
library.add(far);

api.setStore(store);

store.$api = api;

Vue.prototype.$api = api;
Vue.prototype.$navigation = navigation;
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(Storage);
Vue.component('FaIcon', FontAwesomeIcon);

if (GTM_ID) {
  Vue.use(VueGtm, {
    id: GTM_ID,
    defer: true,
    vueRouter: router,
  });
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
