import { Component } from 'vue';

import RouteOptions, { RouteOptionsParameters } from './RouteOptions';

export default class Route {
  readonly name: string;

  readonly options: RouteOptions;

  private constructor(name: string, options?: RouteOptionsParameters) {
    this.name = name;
    this.options = RouteOptions.load(options);
  }

  get component(): Component {
    return this.options.component;
  }

  static create(name: string, options?: RouteOptionsParameters): Route {
    return new Route(name, options);
  }
}
