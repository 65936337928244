import { RouteConfig } from 'vue-router';

import Navigation from '../Navigation.vue';
import NotFound from '../views/NotFound.vue';
import NotSupported from '../views/NotSupported.vue';

const routes: RouteConfig[] = [
  {
    name: 'app',
    path: '/',
    component: Navigation,
  },

  {
    name: 'not-supported',
    path: '/not-supported',
    component: NotSupported,
  },

  {
    name: 'not-found',
    path: '*',
    component: NotFound,
  },
];

export default routes;
