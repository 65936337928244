import {
  ActionContext,
  ActionTree,
  GetterTree,
  MutationTree,
} from 'vuex';

import Student, { StudentJson } from '../../model/student';
import RootState from '../rootState';

class StudentState {
  student: StudentJson | null = null;
}

type StudentContext = ActionContext<StudentState, RootState>;

const getters: GetterTree<StudentState, RootState> = {
  student: (state: StudentState): Student | null => (null === state.student ? null : new Student(state.student)),
};

const mutations: MutationTree<StudentState> = {
  setInfoStudent(state: StudentState, student: Student | null): void {
    state.student = null === student ? null : student.toJSON();
  },
};

const actions: ActionTree<StudentState, RootState> = {
  setInfoStudent(context: StudentContext, student: Student | null): void {
    context.commit('setInfoStudent', student);
  },
};

export default {
  namespaced: true,
  state: new StudentState(),
  getters,
  mutations,
  actions,
};
