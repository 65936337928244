export const APP_VERSION = '0.1';
export const {
  VUE_APP_BACKEND_BASE_URL: BACKEND_BASE_URL,
  VUE_APP_BACKEND_TOKEN_URL: BACKEND_TOKEN_URL,
  VUE_APP_BACKEND_CLIENT_ID: BACKEND_CLIENT_ID,
  VUE_APP_BACKEND_CLIENT_SECRET: BACKEND_CLIENT_SECRET,
  VUE_APP_BACKEND_SCOPES: BACKEND_SCOPES,
  VUE_APP_GTM_ID: GTM_ID = null,
  VUE_APP_I18N_FALLBACK_LOCALE: I18N_FALLBACK_LOCALE = 'fr',
} = process.env;
