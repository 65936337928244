
import {
  Component,
  Prop,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Player from '../../Player.vue';
import AudioQuestion from '../question/AudioQuestion.vue';
import TextQuestion from '../question/TextQuestion.vue';
import Question from '../../../model/question';

@Component({
  components: {
    AudioQuestion,
    TextQuestion,
    Player,
  },
})
export default class MultipleChoiceAnswer extends Vue {
  @Prop()
    question!: Question;

  @Getter('session/currentAnswer')
    currentAnswer!: string[] | null;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Action('session/storeAnswer')
    storeAnswer;

  @Ref()
  readonly players!: Player[];

  get answer(): string[] {
    return null === this.currentAnswer ? [] : this.currentAnswer;
  }

  set answer(answer: string[]) {
    this.storeAnswer(0 === answer.length ? null : answer);
  }

  finishInteraction(): void {
    this.players?.forEach((player: Player): void => {
      player.stopPlayback();
    });
  }
}
