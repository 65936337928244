import {
  ActionContext,
  ActionTree,
  GetterTree,
  MutationTree,
} from 'vuex';

import Question from '../../model/question';
import Exercise, { ExerciseJson } from '../../model/exercise';
import * as MediaKind from '../../model/mediaKind';
import RootState from '../rootState';

class ExerciseState {
  exercise: string | null = null;

  currentQuestionGuid: string | null = null;

  cache: {
    [key: string]: ExerciseJson,
  } = {};
}

type ExerciseContext = ActionContext<ExerciseState, RootState>;

interface ExerciseGetter {
  exercise: Exercise | null;
  exerciseQuestions: Question[];
  cachedExercises: unknown;
  currentQuestion: Question | null;
  currentQuestionIndex: number | null;
  nextQuestion: Question | null;
  hasNextQuestion: boolean;
  previousQuestion: Question | null;
  hasPreviousQuestion: boolean;
  isVideo: boolean;
  isAudio: boolean;
  isImage: boolean;
  isText: boolean;
  isPdf: boolean;
  mediaUrl: string | null;
}

const getters: GetterTree<ExerciseState, RootState> = {
  exercise: (state: ExerciseState): Exercise | null => (null === state.exercise ? null : new Exercise(state.cache[state.exercise])),
  exerciseQuestions: (state: ExerciseState, { exercise }: ExerciseGetter): Question[] => {
    if (null === exercise) {
      return [];
    }

    return exercise.questions.sort((first: Question, second: Question) => (first.position || 0) - (second.position || 0));
  },
  cachedExercises: (state: ExerciseState): {
    [key: string]: ExerciseJson,
  } => state.cache,
  currentQuestion: ({ currentQuestionGuid }: ExerciseState, { exerciseQuestions }: ExerciseGetter): Question | null => {
    if (null === currentQuestionGuid || 0 === exerciseQuestions.length) {
      return null;
    }

    return exerciseQuestions.find((question: Question): boolean => question.guid === currentQuestionGuid) || null;
  },
  currentQuestionIndex: ({ currentQuestionGuid }: ExerciseState, { exerciseQuestions }): number | null => {
    if (null === currentQuestionGuid || 0 === exerciseQuestions.length) {
      return null;
    }

    const index = exerciseQuestions.findIndex((question: Question): boolean => question.guid === currentQuestionGuid);

    return 0 <= index ? index : null;
  },
  nextQuestion: (state: ExerciseState, { currentQuestionIndex, exerciseQuestions }): Question | null => {
    if (0 === exerciseQuestions.length) {
      return null;
    }

    if (null === currentQuestionIndex) {
      return exerciseQuestions.find((): boolean => true);
    }

    const nextIndex = currentQuestionIndex + 1;

    return nextIndex in exerciseQuestions ? exerciseQuestions[nextIndex] : null;
  },
  hasNextQuestion: (state: ExerciseState, { nextQuestion }): boolean => null !== nextQuestion,
  previousQuestion: (state: ExerciseState, { currentQuestionIndex, exerciseQuestions }): Question | null => {
    if (0 === exerciseQuestions.length) {
      return null;
    }

    if (null === currentQuestionIndex) {
      return exerciseQuestions.find(() => true);
    }

    const previousIndex = currentQuestionIndex - 1;

    return previousIndex in exerciseQuestions ? exerciseQuestions[previousIndex] : null;
  },
  hasPreviousQuestion: (state: ExerciseState, { previousQuestion }: ExerciseGetter): boolean => null !== previousQuestion,

  isVideo: (state: ExerciseState, { exercise }: ExerciseGetter): boolean => {
    if (null === exercise) {
      return false;
    }

    return MediaKind.isVideo(exercise);
  },
  isAudio: (state: ExerciseState, { exercise }: ExerciseGetter): boolean => {
    if (null === exercise) {
      return false;
    }

    return MediaKind.isAudio(exercise);
  },
  isImage: (state: ExerciseState, { exercise }: ExerciseGetter): boolean => {
    if (null === exercise) {
      return false;
    }

    return MediaKind.isImage(exercise);
  },
  isText: (state: ExerciseState, { exercise }: ExerciseGetter): boolean => {
    if (null === exercise) {
      return false;
    }

    return MediaKind.isText(exercise);
  },
  isPdf: (state: ExerciseState, { exercise }: ExerciseGetter): boolean => {
    if (null === exercise) {
      return false;
    }

    return MediaKind.isPdf(exercise);
  },

  mediaUrl: (state: ExerciseState, { exercise }: ExerciseGetter, rootState: RootState, {
    'oauth/accessToken': accessToken,
  }): string | null => {
    if (null === exercise || null === exercise.mainMedia || null === exercise.mainMedia.url) {
      return null;
    }

    const mediaUrl = new URL(exercise?.mainMedia?.url);

    mediaUrl.searchParams.set('access_token', accessToken);

    return mediaUrl.href;
  },
};

const mutations: MutationTree<ExerciseState> = {
  resetExerciseState(state: ExerciseState): void {
    Object.assign(state, new ExerciseState());
  },

  setExercise(state: ExerciseState, exercise: Exercise | null): void {
    if (null === exercise || null === exercise.guid) {
      state.exercise = null;

      return;
    }

    state.cache[exercise.guid] = exercise;
    state.exercise = exercise.guid;
  },

  setCurrentQuestion(state: ExerciseState, currentQuestion: Question | string | null): void {
    if (null === currentQuestion) {
      state.currentQuestionGuid = null;

      return;
    }

    state.currentQuestionGuid = 'string' === typeof currentQuestion ? currentQuestion : currentQuestion.guid;
  },
};

const actions: ActionTree<ExerciseState, RootState> = {
  resetExerciseState({ commit }: ExerciseContext): void {
    commit('resetExerciseState');
  },

  load({ commit, dispatch, getters: { cachedExercises } }: ExerciseContext, exercise: Exercise | string | null): void {
    if ('string' === typeof exercise) {
      if (exercise in cachedExercises) {
        dispatch('load', cachedExercises[exercise]);

        return;
      }

      this.$api.get(`exercise-archive/${exercise}`).then((fetchedExercise) => {
        dispatch('load', fetchedExercise);
      });

      return;
    }

    commit('setExercise', exercise);
    commit('setCurrentQuestion', exercise?.questions.find((): boolean => true) || null);
  },

  loadQuestion({ commit }: ExerciseContext, question: Question | string | null): void {
    commit('setCurrentQuestion', question);
  },

  loadNextQuestion({ dispatch, getters: { nextQuestion } }: ExerciseContext): void {
    if (getters.hasNextQuestion) {
      dispatch('loadQuestion', nextQuestion);
    }
  },

  loadPreviousQuestion({ dispatch, getters: { previousQuestion } }: ExerciseContext): void {
    if (getters.hasPreviousQuestion) {
      dispatch('loadQuestion', previousQuestion);
    }
  },
};

export default {
  namespaced: true,
  state: new ExerciseState(),
  getters,
  mutations,
  actions,
};
