
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import Exercise from '../../model/exercise';
import Session from '../../model/session';
import SessionExercise from '../../model/sessionExercise';
import Student from '../../model/student';
import StudentSession from '../../model/studentSession';
import Route from '../../plugins/navigation/model/Route';
import SessionSidebar from './SessionSidebar.vue';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultLogo = require('../../assets/images/logo.png');

@Component({
  components: {
    SessionSidebar,
  },
})
export default class SessionHeader extends Vue {
  @Getter('session/session')
    currentSession!: Session | null;

  @Getter('session/studentSession')
    studentSession!: StudentSession | null;

  @Getter('student/student')
    student!: Student | null;

  @Getter('session/currentExercise')
    currentExercise!: Exercise | null;

  @Getter('exercise/isAudio')
    isAudio!: boolean;

  @Getter('exercise/isVideo')
    isVideo!: boolean;

  @Getter('exercise/isImage')
    isImage!: boolean;

  @Getter('applicationState/isNavigationDisabled')
    isNavigationDisabled!: boolean;

  @Getter('applicationState/isSidebarVisible')
    isSidebarVisible!: boolean;

  @Getter('session/session')
    session!: Session | null;

  @Getter('session/sessionExercises')
    sessionExercises!: SessionExercise[];

  @Action('applicationState/toggleSidebar')
    toggleSidebar;

  // eslint-disable-next-line class-methods-use-this
  get logo(): string {
    return defaultLogo;
  }

  get exercisePosition(): number | null {
    const exercise = this.sessionExercises.find(
      (sessionExercise: SessionExercise): boolean => sessionExercise.exerciseArchive?.guid === this.currentExercise?.guid,
    );

    return exercise ? exercise.position : null;
  }

  get icon(): string {
    if (this.isVideo) {
      return 'film';
    }

    if (this.isAudio) {
      return 'headphones';
    }

    if (this.isImage) {
      return 'image';
    }

    return 'book';
  }

  get iconClass(): string {
    if (this.isVideo) {
      return 'text-media-video';
    }

    if (this.isAudio) {
      return 'text-media-audio';
    }

    if (this.isImage) {
      return 'text-media-image';
    }

    return 'text-media-text';
  }

  get route(): Route {
    return this.$navigation.currentRoute;
  }

  get sidebarVisible(): boolean {
    return this.isSidebarVisible;
  }

  set sidebarVisible(sidebarVisible: boolean) {
    this.toggleSidebar(sidebarVisible);
  }
}
