
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import ZeroPad from '../mixins/zeropad';
import Session from '../model/session';
import StudentSession from '../model/studentSession';
import Countdown from '../plugins/countdown';
import { SESSION_EXERCISE_QUESTION, SESSION_LOGIN } from '../plugins/navigation/routes';

@Component
export default class ResumeSession extends mixins(ZeroPad) {
  countdown = new Countdown();

  @Getter('session/session')
    session!: Session | null;

  @Getter('session/studentSession')
    studentSession!: StudentSession | null;

  @Action('session/finishSession')
    finishSession;

  @Action('applicationState/toggleNavigationDisabled')
    toggleNavigationDisabled;

  get formattedSessionMaximalDuration(): string {
    const maximalDuration = this.session?.maximalDuration;

    if (null === maximalDuration || undefined === maximalDuration) {
      return '';
    }

    return [
      'hours',
      'minutes',
      'seconds',
    ].map((unit: string): string => {
      const length = maximalDuration[unit]();

      return this.zeroPad(length, 2);
    }).join(':');
  }

  get sessionIsExpired(): boolean {
    if (null === this.session || null === this.session.expiresAt) {
      return false;
    }

    return this.session.expiresAt.isSameOrBefore(moment());
  }

  get sessionExpirationIsClose(): boolean {
    if (null === this.session || null === this.session.expiresAt) {
      return false;
    }

    return !this.sessionIsExpired && this.session.expiresAt.isSameOrBefore(moment().add(15, 'minutes'));
  }

  mounted(): void {
    if (
      null === this.session
      || undefined === this.session
      || null === this.studentSession
      || undefined === this.studentSession
    ) {
      this.toggleNavigationDisabled(false);
      this.$navigation.navigate(SESSION_LOGIN);
    }

    this.checkSessionDuration();
  }

  beforeUnmount(): void {
    this.countdown.stop();
  }

  checkSessionDuration(): void {
    const maximalDuration = this.session?.maximalDuration;
    const startedAt = this.studentSession?.startedAt;

    if (null !== maximalDuration && undefined !== maximalDuration) {
      if (
        null === startedAt
        || undefined === startedAt
        || moment().isAfter(moment(startedAt).add(maximalDuration))
      ) {
        this.dropSession();
      } else {
        this.countdown.start(startedAt, maximalDuration);
      }
    }
  }

  dropSession(): void {
    this.toggleNavigationDisabled(false);
    this.finishSession();
    this.$navigation.navigate(SESSION_LOGIN);
  }

  resumeSession(): void {
    this.toggleNavigationDisabled(false);

    if (!this.sessionIsExpired) {
      this.$navigation.navigate(SESSION_EXERCISE_QUESTION);
    } else {
      this.dropSession();
    }
  }
}
