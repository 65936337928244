
import pdf from 'pdfvuer';
import { Component, Prop, Vue } from 'vue-property-decorator';

const MAX_ZOOM = 500;
const MIN_ZOOM = 10;

@Component({
  components: {
    pdf,
  },
})
export default class ExerciseSubjectPdf extends Vue {
  currentPage = 1;

  scale = 1;

  pagesCount = 0;

  @Prop()
    mediaUrl!: string;

  // eslint-disable-next-line class-methods-use-this
  get MAX_ZOOM(): number {
    return MAX_ZOOM;
  }

  // eslint-disable-next-line class-methods-use-this
  get MIN_ZOOM(): number {
    return MIN_ZOOM;
  }

  get currentZoom(): number {
    return Math.round(this.scale * 100);
  }

  set currentZoom(zoom: number) {
    this.scale = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, zoom)) / 100;
  }

  mounted(): void {
    this.currentPage = 1;
    this.pagesCount = 0;
  }

  loadPreviousPage(): void {
    this.currentPage = Math.max(1, this.currentPage - 1);
  }

  loadNextPage(): void {
    this.currentPage = Math.min(Math.max(0, this.pagesCount), this.currentPage + 1);
  }

  zoomIn(): void {
    this.currentZoom = Math.min(MAX_ZOOM, this.currentZoom + 10);
  }

  zoomOut(): void {
    this.currentZoom = Math.max(MIN_ZOOM, this.currentZoom - 10);
  }
}
